@media (max-width: 991px) {
  .innr-btn-align {
    margin-top: 0px;
  }

  .padd-top {
    padding: 40px 0 !important;
  }

  .header-left img {
    width: 38%;
  }

  video {
    height: auto;
  }

  .header-right-parent {
    right: 0px;
  }

  .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row;
  }

  .normal-header {
    padding-top: 30px;
    padding-bottom: 15px;
    margin-bottom: -115px;
    position: relative;
  }

  .menu-icon:checked + label,
  .menu-icon:not(:checked) + label {
    right: 4px;
  }

  .header-nav ul li a.nav1,
  .header-nav ul li a.nav2,
  .header-nav ul li a.nav3,
  .header-nav ul li a.nav4 {
    padding: 0 0 0 50px;
  }
  .header-nav ul li a {
    font-weight: 400;
    font-size: 54px;
    line-height: 66px;
  }
  .nav4 .extra-button {
    font-weight: 400;
    font-size: 54px;
    line-height: 66px;
  }
  .header-nav .head-addr .head-addr-cont {
    padding: 0 0 0 100px;
    margin: 10px 0;
  }
  .head-addr .head-addr-cont:nth-child(1),
  .head-addr .head-addr-cont:nth-child(2) {
    padding: 0 0 22px 100px;
  }

  .inner-page-btn {
    font-size: 12px;
    line-height: 18px;
    padding: 6px 12px;
  }
  .inner-page-btn img {
    width: 6%;
    transition: 0.5s linear;
    margin: 0px 10px 0 0;
  }

  label {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 20px;
    display: block;
  }

  input,
  textarea {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 40px;
    display: block;
    background-color: transparent;
    padding: 0 0 10px 0;
    /* border: 0px; */
    border: 1px solid #fff;
    width: 100%;
  }

  input[type="submit"] {
    font-weight: 400;
    font-size: 31.6283px;
    line-height: 51px;
    text-decoration: underline;
    color: #ffffff;
    margin-bottom: 40px;
    display: block;
    background-color: transparent;
    padding: 0px;
    border: 0px;
    border-bottom: 0px solid #fff;
    text-align: left;
  }

  input::placeholder {
    color: #b1b5c4;
  }

  input:focus-visible,
  textarea:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }

  .footer-nav-parent {
    position: fixed;
    bottom: 20px;
    z-index: 1000;
    left: 6px;
    right: 6px;
  }

  .floating-nav {
    background: rgb(0, 0, 0, 0.5);
    border-radius: 40px;
    padding: 11px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    padding: 8px 18px;
    border-radius: 40px;
    text-decoration: none;
  }

  .footer-nav-parent .active {
    background: linear-gradient(99.46deg, #a673f9 5.65%, #6b6cf9 93.75%);
  }

  .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .button1 {
    font-size: 14px;
    padding: 8px 24px;
    margin-left: 0;
    margin-right: 12px;
  }
  a {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
  }
  h2 {
    font-weight: 400;
    font-size: 42px;
    line-height: 56px;
    margin: 0;
  }
  h2.line span {
    position: absolute;
    font-weight: 400;
    font-size: 54px;
    line-height: 66px;
    margin: 0;
  }
  h2.line {
    position: relative;
    height: 68px;
    overflow: hidden;
  }

  /* ------ Home Page ------ */

  .home-hero h1.line {
    height: 72px;
  }
  .home-hero h1.line span {
    position: absolute;
    font-weight: 300;
    font-size: 56px;
    line-height: 70px;
    color: #ffffff;
  }

  .home-hero-gra-mob {
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/home-hero-bg-mob.png");
    /* background-position: bottom center; */
    background-size: cover;
    background-repeat: no-repeat;
  }
  .home-cover svg {
    width: 38%;
  }
  .contact-hero-gra {
    pointer-events: none;
    opacity: 0.7;
    background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/about-hero-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    z-index: 0;
    position: absolute;
    width: 100%;
    height: 108%;
    right: 0;
  }
  .contact-page {
    padding-top: 120px;
    overflow: hidden;
  }

  /* .home-hero h1{
        font-weight: 400;
        font-size: 40px;
        line-height: 51px;
        color: #FFFFFF;
    } */

  /* .home-section2{
        height: auto;
    } */
  .home-section2 {
    position: relative;
    /* height: 50vh; */
    overflow: hidden;
    background-color: #00000000;
  }

  .home-section2 .row {
    position: relative;
    top: 0;
  }

  .home-about-inner1 {
    padding: 120px 0 80px 0;
  }

  .home-about-inner2-line {
    display: none;
  }

  .mob-gra-text-1 h3 span {
    font-weight: 400;
    font-size: 65.1163px;
    line-height: 65px;
  }

  /* .home-about-inner2-h3-div{
        height: 75px;
        overflow: hidden;
        margin-top: 14px;
    } */

  .mob-gra-text-2 span {
    line-height: 75px;
    background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .home-about-gra {
    opacity: 1;
    background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/home-about-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    background-position: bottom;
    position: absolute;
    width: 100%;
    height: 400px;
    right: 0;
  }

  .home-about-inner2-h32 span {
    line-height: 65px;
    background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .home-infinity {
    height: 150vh;
    padding-top: 120px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
  }

  .home-infinity svg {
    position: fixed;
    top: 0%;
    bottom: 20%;
    left: 5%;
    width: 90% !important;
    height: 90% !important;
    pointer-events: none;
  }

  .home-what-inner1 {
    padding: 80px 0 40px 0;
  }

  .home-what .right {
    display: none;
  }

  .accordion {
    --bs-accordion-color: #fff;
    --bs-accordion-bg: transparent;
    --bs-accordion-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: #fff;
    --bs-accordion-border-width: 0.3px;
    --bs-accordion-border-radius: 0px;
    --bs-accordion-inner-border-radius: 0px;
    --bs-accordion-btn-padding-x: 16px;
    --bs-accordion-btn-padding-y: 22px;
    --bs-accordion-btn-color: #fff;
    --bs-accordion-btn-bg: transparent;
    --bs-accordion-btn-icon: url(https://ik.imagekit.io/fingerprintsfuture/fps/plus.svg);
    --bs-accordion-btn-icon-width: 24px;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.35s
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --bs-accordion-btn-active-icon: url(https://ik.imagekit.io/fingerprintsfuture/fps/close.svg);
    --bs-accordion-btn-focus-border-color: #fff;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0;
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #fff;
    --bs-accordion-active-bg: transparent;
  }

  .accordion-item {
    color: #ffffff;
    background-color: transparent;
    border-left: 0px solid #ffffff;
    border-right: 0px solid #ffffff;
  }

  .accordion-item p {
    margin: 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
  }
  .accordion-body {
    border-top: 0.3px #fff solid;
  }

  .mobile-lottie-acc {
    width: 50px !important;
  }

  .accordion-item h3 {
    margin: 0;
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    color: #ffffff;
  }

  .accordion-item .col-6 svg {
    margin-top: -16px;
  }
  .home-what-inner2 {
    display: none;
  }

  .home-clients h2 {
    padding: 0px 0 0 0;
  }

  .home-clients-c {
    padding-left: 0px;
    margin-bottom: 80px;
    padding-top: 35px;
    padding-bottom: 0px;
  }
  .home-clients-inner {
    padding: 0 0 0 16px;
  }

  /* .home-work h4{
        font-size: 122.959px;
        line-height: 141px;
        font-weight: 400;
        text-align: center;
        margin-bottom: 100px;
    } */

  .fixed-work-parent {
    height: 500px;
  }

  .home-work-parent h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    margin-top: 12px;
  }

  .home-work-parent p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 8px;
    margin-bottom: 40px;
  }

  .home-work-child {
    padding-right: 20px;
  }

  .view-all {
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
    text-align: left;
    color: #e6e8ec;
    text-decoration: underline;
    padding-bottom: 80px;
    padding-left: 16px;
  }

  /* ------ Home Page End ------ */

  /* Footer Component */

  footer {
    position: relative;
    z-index: 5;
    overflow: hidden;
    padding-bottom: 30px;
    background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/noise.svg");
    background: transparent;
  }

  .footer-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    /* background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/footerbg.png); */
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    opacity: 1;
    margin-top: 0px;
  }

  footer h2 {
    padding-top: 80px;
  }

  .footer-content {
    margin-top: 40px;
  }

  footer .container {
    z-index: 1;
    position: relative;
  }

  .footer-content h5 {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
  }

  .footer-content .mail {
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    text-decoration-line: underline;
  }

  .footer-content .number {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
  }

  .footer-content .c-block {
    margin-bottom: 30px;
  }

  .copyrights {
    margin-bottom: 0px;
  }

  .copyrights h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #b1b5c4;
  }

  /* Footer Component End */

  /* ------------- Extra Pages ------------- */

  .extra-hero {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 0;
    background: transparent;
  }

  .extra-hero h1 {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    color: #23262f;
    margin-top: 30px;
  }

  .extra-hero p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #23262f;
    margin-top: 20px;
  }

  .extra-hero .extra-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 14px 20px;
    color: #23262f;
    border: 1px solid #23262f;
    border-radius: 97px;
    text-decoration: none;
    width: 100%;
  }

  .extra-hero .extra-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 14px 20px;
    color: #fff;
    text-decoration: none;
    width: 100%;
  }

  .extra-button-col {
    margin-top: 0px;
  }

  .extra-link-col {
    margin-top: 40px;
  }

  .extra-row {
    display: block;
    justify-content: space-around;
    margin-top: 30px;
  }

  .privacy {
    padding-bottom: 80px;
  }

  .p-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 70vh;
    width: 100%;
  }

  .p-heading h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    color: #23262f;
  }

  .p-heading h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    color: #23262f;
    /* margin-top: 12px; */
  }

  .privacy-content {
    border-top: 0.5px solid #23262f;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #23262f;
  }

  .privacy-content h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 24px;
  }

  .privacy-content-inner1 {
    max-width: 900px;
    margin: 30px 0 30px 0;
  }

  .privacy-content-inner {
    max-width: 900px;
    margin: 0px 0 30px 0;
  }

  .blog-temp-hero .main-hero-blog-row {
    display: flex;
    /* flex-direction: column-reverse; */
  }

  .blog-temp-hero {
    display: flex;
    flex-direction: column-reverse;
    padding: 150px 0 40px 0;
  }

  .blog-temp-hero img {
    width: 100%;
  }

  .bth-left-p {
    display: block;
  }

  .bth-left-p h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .daterow {
    padding-top: 6px;
    border-top: 0.5px solid #ffffff;
  }

  .blog-author {
    text-align: right;
  }

  .bth-left-p h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-transform: uppercase;
    color: #b1b5c4;
  }

  .blog-content h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  .blog-content p {
    font-size: 16.5px;
    line-height: 24px;
  }

  .blog-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-transform: uppercase;
    color: #b1b5c4;
    margin: 0px !important;
  }

  .blog-temp-other {
    padding: 20px 0;
  }

  .blog-temp-other .home-work-child {
    padding: 16px;
  }

  .blog-temp-other h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    margin: 32px 0;
  }

  .blog-featured-img,
  .blog-featured-img img {
    padding: 0;
  }
  .blog-featured-cont h3 {
    font-size: 28px;
    line-height: 36px;
  }
  .blog-featured-tl {
    position: relative;
    margin: 40px 0 20px 0;
  }

  .blogs-post-cont h3 {
    font-size: 24px;
    line-height: 36px;
  }
  .blog-post-tl {
    margin: 30px 0 0 0;
  }

  .blog-uh-bg,
  .blog-uh-bg2,
  .blog-uh-bg3,
  .blog-uh-bg4 {
    padding: 6.944vw;
  }

  .blog-uh-bg1 {
    padding: 0 6.944vw;
  }
  .blog-uh-bg-cont p {
    font-size: 14px !important;
    line-height: 21px !important;
    padding-top: 2.111vw;
  }
  .blog-uh-bg,
  .blog-uh-bg1,
  .blog-uh-bg2,
  .blog-uh-bg3,
  .blog-uh-bg4 {
    display: block;
    text-align: center;
  }
  /* .blog-uh-img3 {
    padding: 0 0 16px 0;
} */

  .blog-uh-cards img {
    width: auto !important;
  }

  /* ------------- Extra Pages End ------------- */

  /* ------ Process Page ------ */

  .process-hero .line span {
    font-size: 54px;
    line-height: 60px;
  }
  .process-hero .line {
    height: 70px;
  }

  .about-hero-gra {
    width: 100%;
    height: 100%;
  }
  .process-hero {
    height: 70vh;
  }
  .process-hero h1 {
    font-size: 38px;
    line-height: 46px;
  }
  .our-approach {
    padding: 60px 0;
  }

  .our-approach-cont {
    padding: 0 0 60px 0;
  }
  .our-approach h2 {
    font-size: 36px;
    line-height: 46px;
  }
  .bdr-ryt {
    border-right: 0;
    margin: 0 0 0 0;
  }
  .our-work-cont h4 {
    font-size: 32px;
    line-height: 40px;
  }
  .our-work-cont li {
    font-size: 16px;
    line-height: 30px;
  }

  .our-process-cont {
    padding: 60px 0;
  }
  .our-process-cont h2 {
    font-size: 206px !important;
    line-height: 204px !important;
  }

  .owpis {
    margin: 0 0 0 0;
    padding: 100px 4px 100px 4px;
    /* flex-direction: column-reverse; */
  }
  .owpis img {
    margin: 0 0 20px 0;
  }
  .owpis-1 img {
    margin: 0 0 20px 0;
  }
  .owpis-1 {
    margin: 0 0 0;
    padding: 0 4px;
  }
  .our-work-process-cont p {
    font-size: 16px;
    line-height: 24px;
  }

  /* ------ Process Page End ------ */

  /* ------ Work Page ------ */
  .work-hero {
    height: 50vh;
  }
  .work-hero-cont h3 {
    font-size: 32px;
    line-height: 40px;
  }

  .nav-tabs .nav-link {
    font-size: 24px;
    line-height: 32px;
    margin: 0 46px 0 0;
    padding: 16px 0;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 24px;
    line-height: 32px;
    padding: 16px 0;
  }
  /* ------ Work Page End ------ */

  /* ------ sunnybee page ------ */

  .our-approach-gra-resp {
    opacity: 0.6;
    transform: rotate(90deg);
    background: linear-gradient(
      55.18deg,
      #f918ff -11.21%,
      #0267ff 110.21%,
      #0085ff 110.21%
    );
    filter: blur(158px);
    border-radius: 296px;
    /* transform: matrix(-0.41, 1.2, -0.61, -0.59, 0, 0); */
    z-index: -1;
    position: absolute;
    width: 558.8px;
    height: 331.6px;
    left: 0;
    top: 400px;
  }

  .sunnybee-hero {
    justify-content: center;
    height: 100%;
    padding: 120px 0 30px 0;
  }

  .sunnybee-hero-cont h2 {
    font-size: 20px;
    line-height: 26px;
    padding: 0 20px 0 0;
  }
  .sunnybee-hero-cont h4 {
    font-size: 32px;
    line-height: 40px;
  }
  .sunnybee-about {
    padding: 30px 0;
    overflow: hidden;
  }

  .sunnybee-about-cont h4 {
    color: #23262f;
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
  }
  .sunnybee-about-cont p {
    color: #23262f;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 0 24px 0 0;
  }
  .sunnybee-hero-listitem {
    display: block;
  }
  .sunnybee-hero-cont {
    padding: 0px 0 0 0;
  }
  .sunnybee-hero-cont ul {
    padding: 24px 0 0 0;
    text-align: left;
  }
  .sunnybee-hero-cont li {
    font-size: 24px;
    line-height: 48px;
  }

  .sunnybee-about-img video {
    max-width: 100%;
    /* height: 100%; */
  }

  .sunnybee-qep {
    padding: 40px 16px 0 16px;
  }
  .sunnybee-qep-cont h4 {
    font-size: 28px;
    line-height: 38px;
  }
  .sunnybee-qep-cont p {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 0 0 20px 0;
  }
  .sunnybee-qep-img-resp img {
    width: 95%;
  }
  .sunnybee-qep-img p {
    left: 18.5%;
  }
  .sunnybee-qe-video2 video {
    padding-top: 40px;
  }

  .sunnybee-ai {
    position: relative;
    padding: 40px 0;
  }
  .sunnybee-ai-cont img {
    width: 100%;
    padding: 0;
  }
  .sunnybee-ai-cont h4 {
    font-size: 28px;
    line-height: 38px;
    padding-top: 30px;
  }
  .sunnybee-ai-cont p {
    font-size: 18px;
    line-height: 28px;
    margin: 0;
    padding: 0 14px 0 0;
  }
  .sunnybee-video4 {
    padding-top: 20px;
  }
  .sunnybee-video5-6 {
    padding-top: 20px;
  }

  .sunnybee-work {
    padding: 40px 0;
  }
  .sunnybee-work h4 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    margin: 22px 0;
  }

  /* ------ sunnybee page End ------ */

  /* ------ Savittr page ------ */

  .savittr-creative-das {
    margin: 40px 0;
    padding: 40px 0;
  }

  .savittr-creative-das h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
  .savittr-creative-das p {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    padding: 0;
  }
  .savittr-creative-das img {
    width: 80%;
    padding: 0 0 20px 0;
  }

  .savittr-video3 video {
    padding: 0 0 20px 0;
  }

  .savittr-img3-4 .img3 {
    padding: 0 0 20px 0;
  }

  .savittr-creative-intra {
    padding: 40px 0;
  }
  .savittr-creative-intra h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    padding: 0;
  }
  .savittr-creative-intra p {
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    padding: 0;
  }
  .savittr-creative-intra img {
    padding: 0 0 20px 0;
  }

  .savittr-creative-contress {
    padding: 0 0 40px 0;
  }
  .savittr-creative-contress h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    padding: 0;
  }
  .savittr-creative-contress p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
  }

  .savittr-img6,
  .savittr-img7 {
    /* width: 50%; */
    text-align: center;
  }
  .savittr-img6 img,
  .savittr-img7 img {
    /* width: 80%; */
    padding: 0 0 20px 0;
  }
  /* ------ Savittr page End ------ */

  /* ------ Dealsplus Website page ------ */

  .dealsplusW-sitecore {
    padding: 10px 0 0 0;
  }
  .dealsplusW-img3-4-5 img {
    padding: 0 0 40px 0;
  }

  .dealsplus-sitecore {
    padding: 40px 0 0 0;
  }
  .dealsplus-img3-4-5 img {
    padding: 0 0 40px 0;
  }
  /* .dealsplusP-img2-resp,
    .dealsplusP-img6-resp {
        padding: 0 0 40px 0;
    } */
  .dealsplus-resp-cont h4,
  .dealsplus-resp-cont1 p {
    padding: 0 12px 0 0;
  }
  /* ------ Dealsplus Website page End ------ */

  /* ------ Fut Bank Page ------ */
  .futbank-ebp p {
    padding: 0;
  }
  .futbank-rd {
    padding: 40px 0;
  }
  .futbank-rd h4 {
    font-size: 24px;
    line-height: 36px;
  }
  .futbank-rd p {
    font-size: 16px;
    line-height: 24px;
    padding: 0 12px 0 0;
  }
  .futbank-img6 img,
  .futbank-img8 img,
  .futbank-img11 img {
    width: 80%;
  }

  .futbank-img5-resp h4 {
    color: #23262f;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  .futbank-img5-resp p {
    color: #23262f;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  /* ------ Fut Bank Page End ------ */

  /* ------------ Drapesanddreams Page ------------ */

  .dandd-img2 img,
  .dandd-img3 img,
  .dandd-img5-6-7 img {
    padding: 0 0 30px 0;
  }
  /* ------------ Drapesanddreams Page End ------------ */
  .blog-author-img {
    width: 4% !important;
  }
  .blog-temp-hero {
    display: flex;
    flex-direction: column;
    padding: 150px 0 40px 0;
  }
}

@media (max-width: 767px) {
  .our-approach-gra {
    opacity: 0.6;
    z-index: -1;
    position: absolute;
    width: 500px;
    height: 1000px;
    top: 0px;
    right: 0px;
  }
  .our-approach-keys {
    margin-top: 40px;
  }
  .home-about-inner-inner2 {
    margin-bottom: 20px;
  }
  .home-work .extra-button-col {
    margin: 20px 16px 80px 20px;
  }
  .home-work .extra-button-col {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }

  .home-work .form-control {
    width: -moz-fit-content;
    width: fit-content;
    margin: 0px 0;
  }
  dd {
    font-size: 30px;
    font-weight: 500;
    display: block;
    margin-top: 20px;
  }

  .home-hero {
    height: 85vh;
  }

  .home-about-inner2 {
    margin-top: 20px;
  }

  .header-left img {
    width: 50%;
  }

  .header-right-parent {
    top: 24px;
    right: 0px;
    mix-blend-mode: normal;
  }

  .normal-header {
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: -92px;
    position: fixed;
    width: 100%;
    background-color: #ffffff;
  }

  .inner-page-btn {
    padding: 4px 10px;
    margin: 0 -16px 0 0;
  }

  .inner-page-btn img {
    width: 12%;
    transition: 0.5s linear;
    margin: -2px 8px 0 0;
  }

  label {
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin-bottom: 12px;
    display: block;
  }

  input,
  textarea {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    margin-bottom: 36px;
    display: block;
    background-color: transparent;
    padding: 0 0 10px 0;
    /* border: 0px; */
    border: 1px solid #fff;
    width: 100%;
    height: 48px;
  }

  .form-control {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    text-decoration: none;
    color: #ffffff;
    margin-bottom: 40px;
    display: block;
    background-color: transparent;
    padding: 8px 24px;
    border: 1px solid #fff;
    text-align: left;
  }

  .form-control:hover,
  .form-control:focus {
    /* display: block; */
    /* width: 100%; */
    /* padding: 0.375rem 0.75rem; */
    /* font-size: 1rem; */
    /* font-weight: 400; */
    /* line-height: 1.5; */
    /* color: #212529; */
    /* background-color: #fff; */
    /* background-clip: padding-box; */
    /* border: 1px solid #ced4da; */
    /* appearance: none; */
    /* border-radius: 0.375rem; */
    /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */

    background-color: white;
    color: #000;
  }

  input::placeholder {
    color: #b1b5c4;
    font-size: 16px;
    line-height: 22px;
  }

  input:focus-visible,
  textarea:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }

  .footer-nav-parent {
    position: fixed;
    bottom: 20px;
    z-index: 1000;
    left: 6px;
    right: 6px;
  }

  .floating-nav {
    background: rgb(0, 0, 0, 0.5);
    border-radius: 40px;
    padding: 11px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    padding: 8px 18px;
    border-radius: 40px;
    text-decoration: none;
  }

  .footer-nav-parent .active {
    background: linear-gradient(99.46deg, #a673f9 5.65%, #6b6cf9 93.75%);
  }

  .container {
    padding-left: 16px !important;
    padding-right: 16px !important;
  }

  .button1 {
    font-size: 14px;
    padding: 8px 24px;
    margin-left: 0;
  }
  a {
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
  }
  h2 {
    font-weight: 400;
    font-size: 34px;
    line-height: 46px;
    margin: 0;
  }

  /* ------ Home Page ------ */

  /* .home-hero-gra-mob{
        z-index: -1;
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('https://ik.imagekit.io/fingerprintsfuture/fps/home-hero-bg-mob.png');
        background-position: bottom center;
        background-size: cover;
        background-repeat: no-repeat;
    } */
  .home-hero-gra {
    pointer-events: none;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/home-hero-bg.png);
    background-position-x: 50%;
    background-position-y: 0%;
    background-size: 400%;
    background-repeat: no-repeat;
    opacity: 0.8;
  }
  .home-cover svg {
    width: 38%;
  }

  .home-hero h1 {
    font-weight: 300;
    font-size: 36px;
    line-height: 51px;
    color: #ffffff;
    padding-right: 10px;
  }

  /* .home-section2{
        height: auto;
    } */
  .home-section2 video,
  .home-section2 img {
    margin-bottom: -6px;
  }
  .home-section2 {
    position: relative;
    /* height: 300px; */
    overflow: hidden;
    background-color: #00000000;
  }

  .home-section2 .row {
    position: relative;
    top: 0;
  }

  .home-about-inner1 {
    padding: 120px 0 80px 0;
  }

  .home-about-inner2-line {
    display: none;
  }

  .mob-gra-text-1 h3 span {
    font-weight: 400;
    font-size: 65.1163px;
    line-height: 65px;
  }

  .mob-gra-text-2 span {
    line-height: 75px;
    background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .home-about-gra {
    opacity: 0.7;
    background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/home-about-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 0;
    background-position: bottom;
    position: absolute;
    width: 100%;
    height: 400px;
    right: 0;
  }

  .home-about-inner2-h32 span {
    line-height: 65px;
    background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .home-infinity {
    height: 150vh;
    padding-top: 120px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
  }

  .home-infinity svg {
    position: fixed;
    top: 0%;
    bottom: 20%;
    left: 5%;
    width: 90% !important;
    height: 90% !important;
    pointer-events: none;
  }

  .home-what-inner1 {
    padding: 80px 0 40px 0;
  }

  .home-what .right {
    display: none;
  }

  .accordion {
    --bs-accordion-color: #fff;
    --bs-accordion-bg: transparent;
    --bs-accordion-transition: color 0.15s ease-in-out,
      background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: #fff;
    --bs-accordion-border-width: 0.3px;
    --bs-accordion-border-radius: 0px;
    --bs-accordion-inner-border-radius: 0px;
    --bs-accordion-btn-padding-x: 16px;
    --bs-accordion-btn-padding-y: 22px;
    --bs-accordion-btn-color: #fff;
    --bs-accordion-btn-bg: transparent;
    --bs-accordion-btn-icon: url(https://ik.imagekit.io/fingerprintsfuture/fps/plus.svg);
    --bs-accordion-btn-icon-width: 24px;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.35s
      cubic-bezier(0.25, 0.46, 0.45, 0.94);
    --bs-accordion-btn-active-icon: url(https://ik.imagekit.io/fingerprintsfuture/fps/close.svg);
    --bs-accordion-btn-focus-border-color: #fff;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0;
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #fff;
    --bs-accordion-active-bg: transparent;
  }

  .accordion-item {
    color: #ffffff;
    background-color: transparent;
    border-left: 0px solid #ffffff;
    border-right: 0px solid #ffffff;
  }

  .accordion-item p {
    margin: 0;
    font-weight: 300;
    font-size: 16px;
    line-height: 32px;
  }
  .accordion-body {
    border-top: 0.3px #fff solid;
  }

  .mobile-lottie-acc {
    width: 50px !important;
  }

  .accordion-item h3 {
    margin: 0;
    font-weight: 300;
    font-size: 24px;
    line-height: 33px;
    color: #ffffff;
  }

  .accordion-item .col-6 svg {
    margin-top: -16px;
  }
  .home-what-inner2 {
    display: none;
  }

  .home-clients h2 {
    padding: 0px 0 0 0;
  }

  .home-clients-c {
    padding-left: 0px;
    margin-bottom: 80px;
    padding-top: 35px;
    padding-bottom: 0px;
  }

  .home-work h4 {
    font-size: 122.959px;
    line-height: 141px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 100px;
  }

  .fixed-work-parent {
    height: 250px;
  }

  .home-work-parent h3 {
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    margin-top: 12px;
  }

  .home-work-parent p {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    margin-top: 8px;
    margin-bottom: 40px;
  }

  .home-work-child {
    padding-right: 20px;
  }

  .view-all {
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    text-align: left;
    color: #e6e8ec;
    text-decoration: underline;
    padding-bottom: 80px;
    padding-left: 16px;
  }

  /* ------ Home Page End ------ */

  /* Footer Component */

  footer {
    position: relative;
    z-index: 5;
    overflow: hidden;
    padding-bottom: 30px;
    background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/noise.svg");
    background: transparent;
  }

  .footer-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/footerbg.png); */
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    opacity: 1;
    margin-top: 0px;
  }

  footer h2 {
    padding-top: 80px;
  }

  .footer-content {
    margin-top: 40px;
  }

  footer .container {
    z-index: 1;
    position: relative;
  }

  .footer-content h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }

  .footer-content .mail {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-decoration-line: none;
  }

  .home-hero .container {
    padding-right: 0px !important;
  }

  .footer-content .number {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    text-decoration: none;
    padding-top: 10px;
    display: block;
  }

  .footer-content .c-block {
    margin-bottom: 30px;
  }

  .copyrights {
    margin-bottom: 0px;
  }

  .copyrights h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }

  /* Footer Component End */

  /* ------------- Extra Pages ------------- */

  .extra-hero {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    z-index: 0;
    background: transparent;
  }

  .extra-hero h1 {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    color: #23262f;
    margin-top: 30px;
  }

  .extra-hero p {
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    color: #23262f;
    margin-top: 20px;
  }

  .extra-hero .extra-button {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 14px 20px;
    color: #23262f;
    border: 1px solid #23262f;
    border-radius: 97px;
    text-decoration: none;
    width: 100%;
  }

  .extra-hero .extra-link {
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    padding: 14px 20px;
    color: #fff;
    text-decoration: none;
    width: 100%;
  }

  .extra-button-col {
    margin-top: 0px;
  }

  .extra-link-col {
    margin-top: 40px;
  }

  .extra-row {
    display: block;
    justify-content: space-around;
    margin-top: 30px;
  }

  .privacy {
    padding-bottom: 80px;
  }

  .p-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 70vh;
    width: 100%;
  }

  .p-heading h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 48px;
    color: #23262f;
  }

  .p-heading h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    text-transform: uppercase;
    color: #23262f;
    /* margin-top: 12px; */
  }

  .privacy-content {
    border-top: 0.5px solid #23262f;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #23262f;
  }

  .privacy-content h4 {
    font-weight: 400;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 24px;
  }

  .privacy-content-inner1 {
    max-width: 900px;
    margin: 30px 0 30px 0;
  }

  .privacy-content-inner {
    max-width: 900px;
    margin: 0px 0 30px 0;
  }

  .blog-temp-hero .main-hero-blog-row {
    display: flex;
    flex-direction: column;
  }

  .blog-temp-hero {
    display: flex;
    flex-direction: column;
    padding: 150px 0 40px 0;
  }

  .blog-temp-hero img {
    width: 100%;
  }

  .blog-author-img {
    width: 4% !important;
  }

  .bth-left-p {
    display: block;
  }

  .bth-left-p h1 {
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    margin-top: 0;
    margin-bottom: 0;
  }
  .bth-left-p-cont {
    margin-left: 0;
    margin-bottom: 40px;
  }

  .daterow {
    position: relative;
    padding-top: 6px;
    border-top: 0.5px solid #ffffff;
  }

  .blog-author {
    text-align: right;
  }

  .bth-left-p h6 {
    font-weight: 400;
    font-size: 14px;
    line-height: 32px;
    text-transform: uppercase;
    color: #b1b5c4;
  }

  .blog-content h2 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .blog-content h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .blog-content p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .blog-date {
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    text-transform: uppercase;
    color: #b1b5c4;
    margin: 0px !important;
  }

  .blog-ww {
    margin: 40px 0;
    padding: 30px 0;
  }
  .blog-ww h2 {
    padding: 0 32px 0 32px;
    margin-bottom: 0;
  }
  .blog-ww p {
    margin: 12px 32px 0 32px;
    padding: 12px 0 0 0;
    border-top: 1px solid #fff;
    border-left: none;
  }
  .txt-outline h4 {
    font-size: 28px;
    line-height: 36px;
  }

  .blog-temp-other {
    padding: 20px 0;
  }

  .blog-du-imgcont {
    padding: 30px 0 0 0;
  }
  .blog-du-imgcont img {
    width: 92%;
    margin-bottom: 16px;
  }
  .blog-du-imgcont .col-md-6:nth-child(2) {
    padding: 0 12px 0 16px;
  }
  /* .blog-du-imgcont .row {
    flex-direction: column-reverse;
} */
  .blog-temp-other .home-work-child {
    padding: 16px;
  }
  .txt-gradi-resp {
    padding: 16px 0 0 0;
  }

  .quote-bg h2,
  .quote-bg p {
    margin-bottom: 0;
    padding: 0 26px 0 26px;
  }
  .quote-bg .blog-du-img3 {
    top: -20px;
    left: 20px;
    width: 12%;
  }

  .blog-du-cardcont p {
    font-size: 16px;
    padding: 0 0px 0 0;
  }

  .blog-temp-other h4 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 400;
    margin: 32px 0;
  }

  .blog-crds-bdr {
    height: auto;
    margin: 20px 0 0 0;
  }
  .blog-post-tl {
    position: relative;
  }
  .blog-temp-other .blog-crds-bdr {
    margin: 0 0 0 0;
  }

  .blog-uh-bg {
    background: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg-resp.png)
      no-repeat #fff;
    background-size: cover;
    padding: 2.778vw 6.944vw;
  }
  .blog-uh-bg1 {
    background: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg-resp.png)
      no-repeat #fff;
    background-size: cover;
  }
  .blog-uh-bg2 {
    background: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg-resp.png)
      no-repeat #fff;
    background-size: cover;
    padding: 2.778vw 6.944vw 0 6.944vw;
  }
  .blog-uh-bg3 {
    background: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg-resp.png)
      no-repeat #fff;
    background-size: cover;
    padding: 0 6.944vw 2.778vw 6.944vw;
  }
  .blog-uh-bg4 {
    background: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg-resp.png)
      no-repeat #fff;
    background-size: cover;
  }
  /* .blog-uh-cards img {
    width: 100% !important;
} */
  .blog-uh-bg-cont p {
    font-size: 12px !important;
    line-height: 21px !important;
    padding-top: 2.111vw;
    color: #fff;
    font-style: italic;
  }
  .blog-uh-cards .blog-du-card {
    width: 97%;
  }

  /* ------------- Extra Pages End ------------- */

  /* ------ Process Page ------ */
  .chip span {
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
  }
  .chip {
    margin: 0 10px 10px 0;
    padding: 4px 16px;
  }
  .about-hero-gra {
    width: 126%;
    height: 116%;
    bottom: -186px;
  }
  .process-hero {
    height: 80vh;
  }
  .process-hero h1 {
    font-size: 38px;
    line-height: 46px;
  }
  .our-process-img {
    position: relative;
    z-index: 1;
  }
  .our-approach {
    padding: 60px 0;
  }
  .our-approach-cont {
    padding: 0 0 60px 0;
  }
  .our-approach h2 {
    font-size: 26px;
    line-height: 36px;
    padding: 0 0 8px 0;
  }
  .our-approach-keys {
    padding: 16px 0 0 0;
  }
  .bdr-ryt {
    border-right: 0;
    margin: 0 0 0 0;
  }
  .our-work-cont h4 {
    font-size: 32px;
    line-height: 40px;
  }
  .our-work-cont li {
    font-size: 16px;
    line-height: 30px;
  }

  .our-process-cont {
    padding: 60px 0;
  }
  .our-process-cont h2 {
    font-size: 90px !important;
    line-height: 90px !important;
  }
  .chip-p-m {
    padding-right: 0px;
  }

  .owpis {
    margin: 0 0 0 0;
    padding: 60px 4px;
    flex-direction: column-reverse;
  }
  .owpis img {
    margin: 0 0 20px 0;
  }
  .owpis-1 img {
    margin: 0 0 20px 0;
  }
  .owpis-1 {
    margin: 0 0 0;
    padding: 0 4px;
  }
  .our-work-process-cont p {
    font-size: 16px;
    line-height: 26px;
  }

  /* ------ Process Page End ------ */

  /* ------ Work Page ------ */
  .work-hero-cont h3 {
    font-size: 32px;
    line-height: 40px;
  }
  .work-hero-cont-h4 h4 {
    font-size: 42px;
  }
  .work-hero-cont-p {
    font-size: 20px;
    line-height: 28px;
    margin-top: 24px;
  }
  .nav-tabs .nav-link {
    font-size: 16px !important;
    line-height: 22px !important;
    margin: 0 46px 0 0;
    padding: 16px 0;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    font-size: 16px !important;
    line-height: 22px !important;
    padding: 16px 0;
  }
  /* ------ Work Page End ------ */

  /* ------ sunnybee page ------ */

  .our-approach-gra-resp {
    opacity: 0.6;
    transform: rotate(90deg);
    background: linear-gradient(
      55.18deg,
      #f918ff -11.21%,
      #0267ff 110.21%,
      #0085ff 110.21%
    );
    filter: blur(158px);
    border-radius: 296px;
    /* transform: matrix(-0.41, 1.2, -0.61, -0.59, 0, 0); */
    z-index: -1;
    position: absolute;
    width: 558.8px;
    height: 331.6px;
    left: 0;
    top: 400px;
  }

  .sunnybee-hero {
    display: block;
    height: 100%;
    padding: 140px 0 30px 0;
  }

  .sunnybee-hero-cont h2 {
    font-size: 16px;
    line-height: 0;
    padding: 0 20px 0 0;
    margin-bottom: 16px;
  }
  .sunnybee-hero-cont h4 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 300;
  }
  .sunnybee-about {
    padding: 30px 0;
    overflow: hidden;
  }

  .sunnybee-about-cont h4 {
    color: #23262f;
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
  }
  .sunnybee-about-cont p {
    color: #23262f;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    padding: 10px 24px 0 0;
  }
  .sunnybee-hero-listitem {
    display: block;
  }
  .sunnybee-hero-cont {
    padding: 0px 0 0 0;
  }
  .sunnybee-hero-cont ul {
    padding: 10px 0 0 0;
    text-align: left;
  }
  .sunnybee-hero-cont li {
    font-size: 12px;
    line-height: 32px;
  }

  .sunnybee-about-img video {
    padding: 0 0 18px 0;
  }

  .sunnybee-qep {
    padding: 40px 16px 0 16px;
  }
  .sunnybee-qep-cont h4 {
    font-size: 24px;
    line-height: 36px;
    text-align: center;
  }
  .sunnybee-qep-cont p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    padding: 0 0 20px 0;
  }
  .sunnybee-qep-img-resp img {
    width: 95%;
  }
  .sunnybee-qe-video2 video {
    padding-bottom: 40px;
    padding-top: 0px;
  }

  .sunnybee-ai {
    position: relative;
    padding: 40px 0;
  }
  .sunnybee-ai-cont img {
    width: 100%;
    padding: 0;
  }
  .sunnybee-ai-cont h4 {
    font-size: 24px;
    line-height: 36px;
    padding-top: 30px;
  }
  .sunnybee-ai-cont p {
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    padding: 0 14px 0 0;
  }
  .sunnybee-ai-colr {
    flex-direction: column-reverse;
  }
  .sunnybee-video4 {
    padding-top: 20px;
  }
  .sunnybee-video5-6 {
    padding-top: 20px;
  }
  .sunnybee-video5-6 .video5 {
    padding-bottom: 20px;
  }

  .sunnybee-work {
    padding: 40px 0;
  }
  .sunnybee-work h4 {
    font-size: 26px;
    line-height: 34px;
    font-weight: 400;
    margin: 22px 0;
  }

  /* ------ sunnybee page End ------ */

  /* ------ Savittr page ------ */

  .savittr-creative-das {
    margin: 40px 0;
    padding: 40px 0;
  }

  .savittr-creative-das h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
  }
  .savittr-creative-das p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
  }
  .savittr-creative-das img {
    width: 80%;
    padding: 0 0 20px 0;
  }

  .savittr-video3 video {
    padding: 0 0 20px 0;
  }

  .savittr-img3-4 .img3 {
    padding: 0 0 20px 0;
  }

  .savittr-creative-intra {
    padding: 40px 0;
  }
  .savittr-creative-intra h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    padding: 0;
  }
  .savittr-creative-intra p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
  }
  .savittr-creative-intra img {
    padding: 0 0 20px 0;
  }

  .savittr-creative-colr {
    flex-direction: column-reverse;
  }

  .savittr-creative-contress {
    padding: 0 0 40px 0;
  }
  .savittr-creative-contress h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 36px;
    padding: 0;
  }
  .savittr-creative-contress p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: 0;
  }

  .savittr-img6,
  .savittr-img7 {
    width: 50%;
    text-align: center;
  }
  .savittr-img6 img,
  .savittr-img7 img {
    width: 80%;
    padding: 0 0 20px 0;
  }
  /* ------ Savittr page End ------ */

  /* ------ Dealsplus Website page ------ */

  .dealsplusW-sitecore {
    padding: 10px 0 0 0;
  }
  .dealsplusW-img3-4-5 img {
    padding: 0 0 40px 0;
  }

  .dealsplus-sitecore {
    padding: 40px 0 0 0;
  }
  .dealsplus-img3-4-5 img {
    padding: 0 0 40px 0;
  }
  .dealsplusP-img2-resp,
  .dealsplusP-img6-resp {
    padding: 0 0 40px 0;
  }
  .dealsplus-resp-cont h4,
  .dealsplus-resp-cont1 p {
    padding: 0 12px 0 0;
  }
  /* ------ Dealsplus Website page End ------ */

  /* ------ Fut Bank Page ------ */
  .futbank-ebp p {
    padding: 0;
  }
  .futbank-rd {
    padding: 40px 0;
  }
  .futbank-rd h4 {
    font-size: 24px;
    line-height: 36px;
    font-weight: 300;
  }
  .futbank-rd p {
    font-size: 16px;
    line-height: 24px;
    padding: 0 12px 0 0;
  }
  .futbank-img6 img,
  .futbank-img8 img,
  .futbank-img11 img {
    width: 80%;
  }
  .futbank-img5-resp img {
    width: 65%;
  }

  .futbank-img5-resp h4 {
    color: #23262f;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
  }
  .futbank-img5-resp p {
    color: #23262f;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
  }
  /* ------ Fut Bank Page End ------ */

  /* ------------ Drapesanddreams Page ------------ */

  .dandd-img2 img,
  .dandd-img3 img,
  .dandd-img5-6-7 img {
    padding: 0 0 30px 0;
  }
  /* ------------ Drapesanddreams Page End ------------ */

  /* ------------ Authorcafe Page ------------ */

  .authorcafe-img {
    background-size: contain;
    margin: 0 0 40px 0;
  }

  .authorcafe-img4 {
    padding: 0 0 30px 0;
  }
  .authorcafe-img6 img {
    padding: 30px 0 0 0;
  }

  /* ------------ Authorcafe Page End ------------ */

  .marquee-dot {
    margin-top: -10px;
    width: 12px;
  }
  .career-ll-cont {
    margin: 60px 0;
  }
  .our-principle-cont h3 {
    font-size: 24px;
    height: 32px;
  }
  .our-principle-cont h2 {
    font-size: 64px;
    line-height: 64px;
  }
  .career-ll-h2 {
    height: 74px;
  }
  .our-principle-cont p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-top: 30px;
  }
  .open-position-card {
    margin: 0 0 14px 0;
    padding: 16px 12px;
  }
  .open-position-card-cont h4 {
    font-size: 24px;
    line-height: 32px;
  }
  .open-position-card-cont p {
    font-size: 12px;
    line-height: 16px;
  }
  .open-position-card-cont a {
    font-size: 14px;
    line-height: 22px;
    padding: 12px 26px;
  }

  .careers-rq-cont {
    padding: 40px 0 0 0;
  }
  .careers-rq-cont h3 {
    font-size: 28px;
    line-height: 36px;
  }
  .careers-rq-cont li {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 12px;
  }

  .careers-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/careers-rgb.png");
    background-size: cover;
    background-position: left;
    background-repeat: no-repeat;
    opacity: 1;
    margin-top: 0px;
  }
  .blog-chn-card {
    height: auto;
    overflow: hidden;
  }
  .blog-chn-card img {
    height: 450px;
    width: 100% !important;
  }

  /* ------------------------------------ V2 ------------------------------------- */

  .services2 .chips-cover {
    margin-top: 0;
  }

  .back-btn {
    margin-bottom: 0;
  }
  .nwv-blog1-hero-adt {
    margin-bottom: 32px;
  }

  .blog-uh-cards img {
    width: 100% !important;
    height: 365px;
  }
}

.home2none600 {
  display: none;
}

@media (max-width: 600px) {
  /*----------------------------------------------------------- Home2 -----------------------------------------------------------*/

  .home2none600 {
    display: block;
  }

  .home2 .home-hero {
    height: auto;
    min-height: auto;
    z-index: 1;
  }

  .home2 .home-hero .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-height: auto;
    padding-top: 200px;
    padding-bottom: 100px;
  }

  .home2 .home-hero-content {
    margin-bottom: 0vw;
    width: 100%;
  }

  .home2 .home-hero-gra {
    pointer-events: none;
    z-index: -1;
    width: 150%;
    height: 250%;
    position: absolute;
    right: -50vw;
    top: 0;
    bottom: 0;
    background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/h2-hbg2.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  .home2 .home-hero-content h1 {
    font-size: 48.83px;
    line-height: 61.1px;
    font-weight: 300;
  }

  .home2 .home-hero-content p {
    font-size: 20px;
    line-height: 28px;
    margin-top: 24px;
  }

  .home-hero-content .extra-button-col {
    margin: 24px 0 0 0;
  }

  .home2 .marquee-parent {
    border-left: 0px;
    border-right: 0px;
    position: relative;
    max-width: 100%;
    height: 90px;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .home2 .marquee-e {
    font-size: 31.25px;
    line-height: 40px;
    display: flex;
  }

  .home2 .marquee-e span {
    display: inline-block;
    align-self: center;
    width: 12.093px;
    height: 12.093px;
    border-radius: 12.093px;
    margin: 0 14px;
  }

  .home2 .marquee-parent .content {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee2 13s linear infinite;
  }

  @keyframes marquee2 {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-60%);
    }
  }

  .home2 .home-about-inner1 {
    padding: 100px 0;
  }

  .home2 .home-about .shrink {
    max-width: 91.487vw;
  }

  .home2 .home-about-h2 h2 {
    font-size: 31.25px;
    line-height: 40px;
    font-weight: 300;
  }

  .home2 .home-about-h2 p {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    margin: 0;
    margin-top: 24px;
  }

  .home2 .home-about .extra-button-col {
    margin-top: 0px;
  }

  .home-about-h2 {
    padding-bottom: 26px;
  }

  .home-about-new-inner2 {
    display: block;
    padding: 0 0 100px 0;
  }

  .home-what-inner2-mobile {
    padding: 0 0 100px 0;
  }

  .home-about-new-inner2 .cover {
    margin-right: 0vw;
  }

  .home-about-new-inner2 h3 {
    font-weight: 300;
    font-size: 2.713vw;
    line-height: 2.778vw;
    margin: 24px 0;
  }

  .home-about-new-inner2 p {
    color: #b1b5c3;
    font-size: 1.389vw;
    font-weight: 300;
    line-height: 2.778vw;
  }

  .home-cta {
    margin: 100px 0 0 0;
    padding: 100px 0;
    border-top: 0.5px #fff solid;
    border-bottom: 0.5px #fff solid;
  }

  .home-cta .container {
    display: block;
    justify-content: space-between;
    align-items: center;
  }

  .home-cta .left {
    display: block;
    align-items: center;
    justify-content: flex-start;
  }

  .home-cta .right {
    display: block;
    align-items: center;
    justify-content: flex-end;
  }

  .home-what-inner2 {
    border-top: 0.5px solid white;
  }

  .home-what-inner2-row {
    margin: 0;
    overflow: hidden;
    height: 50vw;
  }

  .home2 .home-client {
    margin: 0px;
  }

  .home-client .home-about-inner1 {
    padding-bottom: 64px;
    padding-top: 0;
  }

  .home-faq {
    margin: 0px 0 100px 0;
    padding: 100px 0;
    border-top: 0.5px #fff solid;
    border-bottom: 0.5px #fff solid;
  }

  .home2 footer h2 {
    padding-top: 0 !important;
    font-size: 31.25px;
    line-height: 40px;
    font-weight: 300;
  }

  .home-faq .home-about-inner1 {
    padding-bottom: 40px;
    padding-top: 0;
  }

  .home-faq .accordion {
    max-width: 100%;
  }

  .home-faq .accordion-button::after {
    width: 20px;
    height: 20px;
    background-size: 20px;
  }

  .home-faq .accordion-button {
    padding: 24px 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 300;
    background-color: transparent;
  }

  .home-faq .accordion-body {
    padding: 16px 0 32px 0;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
  }
  .home2 .footer-bg {
    display: none;
  }

  /*----------------------------------------------------------- Home2 End -----------------------------------------------------------*/
}

@media (max-width: 375px) {
  .sunnybee-hero {
    display: flex;
    height: 100%;
    padding: 230px 0 0px 0;
    margin-bottom: 80px;
  }
}

@media (max-width: 425px) {
  .header-left img {
    width: 100%;
  }
  .inner-page-btn img {
    width: 12% !important;
    transition: 0.5s linear;
    margin: -2px 8px 0 0;
  }

  .innr-btn-align {
    text-align: right !important;
    width: 34%;
  }

  .blog-crds-bdr h3 {
    height: auto;
    margin-bottom: 0;
  }
  .blogs-post-cont .home-work-child {
    margin: 20px 0 0 0;
    padding: 0;
  }

  .careers-hero {
    height: calc(100vh - 68px);
  }
  .marquee {
    height: 68px;
  }
  .marquee .content {
    font-size: 58px;
    line-height: 66px;
  }
  /* ------------ E wallet Page ------------ */

  .ewallet-extra-text {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .ewallet-extra-text h4 {
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
  .e-wallet-w {
    display: flex;
    justify-content: center;
  }

  .e-wallet-w img {
    width: 100%;
    margin-bottom: -180px;
    margin-top: 40px;
  }
  .e-wallet-w2 {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-color: #23262f;
    height: 490px;
    margin-bottom: 20px;
  }

  .e-wallet-w2 img {
    width: 75%;
    margin-top: 60px;
    margin-bottom: 0;
  }

  .ew-resp img {
    margin-bottom: 20px;
  }

  /* ------------ E wallet Page End------------ */

  .tnq-img3 {
    position: relative;
    top: 10px;
    right: 0;
  }
  .tnq-img2-3 {
    margin-bottom: 60px;
  }
  .tnq-img2-3 img {
    padding: 0;
  }
  .tnq-img2 h4,
  .tnq-img3 h4 {
    margin: 12px 0;
  }
  .tnq-bg-video {
    position: relative;
    width: auto;
    height: auto;
    overflow: hidden;
  }
  .tnq-bg-video video {
    width: auto;
    height: 300px;
  }
  .tnq-img4 img {
    position: absolute;
    top: -20px;
    left: -20px;
    height: auto;
    pointer-events: none;
    width: 111%;
    max-width: 111%;
  }
  .tnq-bg {
    position: relative;
    margin: 0 0 40px 0;
  }
  .tnq-img-resp2 {
    padding: 24px 0 10px 0 !important;
  }

  .resp-img-width img {
    width: 100% !important;
  }

  /* Landing */

  .landing-cta {
    padding: 50px 0 0 0;
    border: none;
  }

  .landing-button-side {
    align-items: flex-start;
    padding-top: 20px;
  }

  .whatsapp-landing {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 100;
  }

  .home-testimonials-inner {
    max-width: 100%;
    margin-left: 20px;
    margin-top: 30px;
    padding-right: 50px;
  }

  .home-testimonials-inner h3 {
    font-weight: 300;
    font-size: 18px;
    line-height: 28px;
    color: #e6e8ec;
    margin-bottom: 12px;
  }

  .home-testimonials-inner h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  .home-testimonials-inner h5 {
    font-weight: 30;
    font-size: 13px;
    line-height: 31px;
    color: #ffffff;
    margin-bottom: 40px;
  }

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 80%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, 20%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }

  .slick-prev:before,
  .slick-next:before {
    width: 51px;
    height: 51px;
    display: block;
  }

  .slick-prev {
    left: 60% !important;
    right: 100px;
  }

  .slick-next {
    right: 0px;
    left: 80%;
  }

  .radios-budget .form-check {
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 8px;
    padding: 0px;
  }

  .radios-budget label {
    display: inline-block;
    padding: 6px 16px;
    border-radius: 100px;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
  }

  .radios-budget input[type="radio"] {
    display: none;
  }

  .landingparent .home-hero h1 {
    font-weight: 300;
    font-size: 31px;
    line-height: 45px;
    color: #ffffff;
    padding-right: 20px;
  }

  .radios-budget input[type="radio"]:checked + label {
    display: inline-block;
    padding: 6px 16px;
    border-radius: 100px;
    background-color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #000 !important;
  }

  .landing-hero img {
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    width: 100%;
    align-self: center;
    z-index: 1;
  }
  .landing-hero .container {
    justify-content: flex-end;
    padding-bottom: 80px;
  }
  .landing-hero {
    height: 100vh;
  }
  .landing-hero-gra {
    pointer-events: none;
    z-index: -1;
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-herdffo-bg.png);
    background-position-x: 50%;
    background-position-y: 0%;
    background-size: 180%;
    background-repeat: no-repeat;
  }
  .testimonials .slick-list {
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
  }

  .col-revers {
    flex-direction: column-reverse;
  }

  /* Blog styles */

  .blog-du-imgcont .col-md-6:nth-child(2) {
    padding: 12px 12px 0 12px;
  }

  .blog-author-img {
    width: 6% !important;
  }
  .v2-blog .v2-blog-sidebar-cont {
    border: none;
  }
  .v2-blog-sidebar-tags,
  .v2-blog-sidebar-posts,
  .v2-blog-sidebar-social {
    margin-left: 0;
  }
  .v2-blog .blog-content h2 {
    font-size: 20px;
    line-height: 28px;
  }
  .v2-blog .txt-outline h4 {
    font-size: 30px;
    line-height: 30px;
  }

  .v2-blog .blog-du-cardcont .txt-outline h4 {
    font-size: 2rem;
    font-weight: 300;
    line-height: 2.75rem;
  }

  .v2-blog .quote-bg .blog-du-img3 {
    top: -20px;
    left: 20px;
    width: 12%;
  }

  .nwv-blog1-hero h1 {
    font-size: 2.25rem;
    font-weight: 600;
    line-height: 2.75rem;
    margin-bottom: 16px;
  }
  .nwv-blog1-hero h2 {
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75rem;
    margin-bottom: 24px;
  }
  .nwv-blog1-hero p {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
  }

  .blog-chn-card img {
    height: 650px;
  }

  .blog-uh-cards img {
    height: 540px;
  }
  .blog-adn-card {
    height: 470px;
  }
  .v2-blog .blog-du-card .blog-du-card-img {
    height: 330px;
  }
}

@media (max-width: 1024px) {
  .inner-page-btn img {
    width: 6%;
  }
  .inner-page-btn {
    padding: 6px 16px;
  }

  .bth-left-p-cont {
    margin-left: 0;
  }
  .blog-temp-parent .col-lg-2 {
    display: none;
  }
  .blog-temp-parent .col-lg-8 {
    width: 100%;
  }
  .v2-blog .col-lg-8 {
    padding-right: 12px;
  }
  .v2-blog .col-lg-4 {
    width: 100%;
  }
  /* NT changes */
  .v2-blog .v2-blog-sidebar {
    position: relative;
    padding: 40px 0;
  }
  .v2-blog .v2-blog-sidebar-cont {
    border: none;
    padding-bottom: 0;
  }
  .v2-blog-sidebar-tags,
  .v2-blog-sidebar-posts,
  .v2-blog-sidebar-social {
    margin-left: 0;
  }
  .post-container p {
    width: auto;
  }
  .sidebar-card {
    display: none;
  }

  .para-width p {
    width: auto;
  }
  .axe-cont p {
    width: auto;
  }
}

@media only screen and (max-width: 1260px) and (min-width: 992px) {
  .sunnybee-hero-cont h2 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 18px;
  }
  .sunnybee-hero-cont h2.line span {
    font-size: 2rem;
    line-height: 1.75rem;
  }
  .sunnybee-hero-cont h2.line {
    height: 3rem;
  }
  .sunnybee-hero-cont li {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
  .sunnybee-about-cont h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .sunnybee-qep-cont h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .sunnybee-qep-img p {
    left: 14.5%;
  }
  .sunnybee-work {
    padding: 100px 0 0 0;
  }
  .sunnybee-work h4 {
    font-size: 2rem;
    line-height: 3.25rem;
    margin: 30px 0;
  }
  .savittr-creative-das p {
    padding: 0px 0px 0 0;
  }
  .savittr-creative-intra h4 {
    padding: 0 0 0 0;
  }
  .savittr-creative-intra p {
    padding: 0 30px 0 0;
  }
  .savittr-creative-contress h4 {
    padding: 0 0px 0 0;
  }
  .savittr-creative-contress p {
    padding: 0 0px 0 0;
  }
  .bg-gray {
    margin-bottom: 60px;
  }

  .v2-home-banner-shape-img img {
    position: absolute;
    top: 0;
    right: 0;
    width: 55%;
    height: auto;
  }
  .home2 .home-hero-content h1.line {
    height: 4.5rem;
  }
  .home2 .home-hero-content h1.line span {
    font-size: 3.625rem;
    line-height: 3.75rem;
    color: #23262f;
  }
  .home2 .home-hero-content p {
    font-size: 1.25rem;
    line-height: 1.75rem;
    margin-top: 12px;
  }

  .home2 .home-about-h2 h2.line {
    height: 2.875rem;
  }
  .home2 .home-about-h2 h2 span {
    color: #23262f;
    font-size: 1.875rem;
    line-height: 2rem;
  }
  .home2 .home-about-h2 p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    margin-top: 8px;
  }
  .v2-home-shape-img2 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: auto;
  }
  .home-about-new-inner2 h3 {
    font-size: 1.875rem;
    line-height: 2rem;
    margin: 24px 0;
  }
  .home-about-new-inner2 p {
    color: #23262f;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 300;
  }

  .slogan {
    text-rendering: optimizeLegibility;
    margin: 0px;
    font-size: 1.875rem;
    color: #e6e8ec;
  }

  .slogan div {
    margin: -7px 0px 0 6px;
  }

  .v2-home-faq .home-about-h2 h2 span {
    color: #e6e8ec;
  }
  .home-testimonials-inner {
    min-width: 80%;
  }

  /* Axe project */
  .axe-cont {
    padding: 40px 30px;
    gap: 20px;
  }
}
@media only screen and (max-width: 991px) and (min-width: 768px) {
  .normal-header {
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: -92px;
    position: relative;
    width: 100%;
    background-color: transparent;
  }

  .sunnybee-hero-cont h2 {
    font-size: 0.875rem;
    line-height: 1.25rem;
    margin-bottom: 18px;
  }
  .sunnybee-hero-cont h2.line span {
    font-size: 2rem;
    line-height: 1.75rem;
  }
  .sunnybee-hero-cont h2.line {
    height: 3rem;
  }
  .sunnybee-hero-cont li {
    font-size: 0.75rem;
    line-height: 1.5rem;
  }
  .sunnybee-about-cont h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .sunnybee-about-img img {
    height: auto;
  }
  .sunnybee-qep-cont h4 {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .sunnybee-qep-img p {
    left: 14.5%;
  }
  .sunnybee-work {
    padding: 100px 0 0 0;
  }
  .sunnybee-work h4 {
    font-size: 2rem;
    line-height: 3.25rem;
    margin: 30px 0;
  }
  .savittr-creative-das p {
    padding: 0px 0px 0 0;
  }
  .savittr-creative-intra h4 {
    padding: 0 0 0 0;
  }
  .savittr-creative-intra p {
    padding: 0 30px 0 0;
  }
  .savittr-creative-contress h4 {
    padding: 0 0px 0 0;
  }
  .savittr-creative-contress p {
    padding: 0 0px 0 0;
  }
  .bg-gray {
    margin-bottom: 40px;
  }
  .sunnybee-ai-colr {
    flex-direction: row;
  }
  .sunnybee-qe-video2 video {
    padding-top: 40px;
  }
  .savittr-creative-colr {
    flex-direction: row;
  }
  .savittr-img6,
  .savittr-img7 {
    width: 25%;
    text-align: center;
  }
  .futbank-rd {
    position: relative;
    padding: 120px 0 40px 0;
  }

  .home2 .home-hero .container {
    /* min-height: 100vh; */
    min-height: 66.667vw;
  }
  .v2-home-banner-shape-img img {
    position: absolute;
    top: 0;
    right: 0;
    width: 55%;
    height: auto;
  }
  .home2 .home-hero-content h1.line {
    height: 3.438rem;
  }
  .home2 .home-hero-content h1.line span {
    font-size: 3rem;
    line-height: 3.125rem;
    color: #23262f;
  }
  .home2 .home-hero-content p {
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-top: 12px;
  }

  .home2 .home-about-h2 h2.line {
    height: 2.875rem;
  }
  .home2 .home-about-h2 h2 span {
    color: #23262f;
    font-size: 1.875rem;
    line-height: 2rem;
  }
  .home2 .home-about-h2 p {
    font-size: 1rem;
    line-height: 1.5rem;
    margin: 0;
    margin-top: 8px;
  }
  .v2-home-shape-img2 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: auto;
  }
  .home-about-new-inner2 h3 {
    font-size: 1.875rem;
    line-height: 2rem;
    margin: 24px 0;
  }
  .home-about-new-inner2 p {
    color: #23262f;
    font-size: 1rem;
    line-height: 2rem;
    font-weight: 300;
  }

  .slogan {
    text-rendering: optimizeLegibility;
    margin: 0px;
    font-size: 1.875rem;
    font-weight: 300;
    color: #e6e8ec;
  }

  .slogan div {
    margin: -7px 0px 0 6px;
  }

  .v2-home-cta .home-about-h2 h2.line {
    margin-right: -70px;
  }

  .home-client .home-about-inner1 {
    padding-bottom: 0;
  }

  .home-testimonials-inner {
    min-width: 80%;
  }

  .home-faq {
    padding: 60px 0;
  }
  .home-faq .home-about-inner1 {
    padding-bottom: 0;
  }
  .v2-home-faq .home-about-h2 h2 span {
    color: #e6e8ec;
  }

  .v2-home-what-inner2-mobile .accordion-item {
    color: #ffffff;
    background-color: transparent;
    border-top-color: #000000;
    border-bottom-color: #000000;
  }
  .v2-home-what-inner2-mobile .accordion-item h3 {
    color: #000000;
  }
  .v2-home-what-inner2-mobile .accordion-item p {
    color: #000;
  }

  .header-right {
    margin-top: 12px;
  }
  .button1 {
    font-size: 12px;
    line-height: 18px;
    padding: 6px 16px;
    margin-right: 8px;
    margin-left: -12px;
    height: auto;
  }
  .header-right-parent {
    top: 19px;
    right: 0px;
    mix-blend-mode: normal;
  }
  .schedule {
    background: white;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #23262f;
    height: 41px;
    border: 1px solid #23262f;
    border-radius: 97px;
    padding: 6px 16px;
    text-decoration: none;
    transition: 0.5s linear;
    /* margin-left: 20px; */
    mix-blend-mode: exclusion !important;
  }

  .schedule:hover {
    color: #23262f;
    background: #f4f5f6;
  }
  .footer-bg {
    background-color: transparent;
  }
  .home2 .footer-bg {
    background-color: transparent;
  }
  footer h2 {
    color: #23262f;
  }
  label {
    color: #23262f;
  }
  input,
  textarea {
    color: #23262f;
    border: 0.5px solid #23262f;
  }
  .form-control {
    color: #23262f;
    border: 1px solid #23262f;
  }
  .form-control:hover,
  .form-control:focus {
    background-color: rgb(255, 255, 255);
    color: #23262f;
  }
  .footer-content p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #23262f;
  }
  .footer-content .mail {
    color: #23262f;
  }
  .footer-content .number {
    color: #23262f;
  }
  .copyrights h6 {
    color: #23262f;
  }

  .services2 .process-hero h1.line {
    height: 3.75rem;
  }
  .services2 .process-hero .process-hero-content h1.line span {
    font-weight: 200;
    font-size: 3rem;
    line-height: 3.75rem;
  }

  .services2 .process-approach {
    margin-bottom: 0;
  }
  .services2 .our-approach-cont h2.line {
    display: auto !important;
    justify-content: center;
  }
  .services2 .our-approach-cont h2.line {
    height: 52px;
  }
  .services2 .our-approach-cont h2 span {
    font-weight: 300;
    font-size: 38px;
    line-height: 52px;
    margin: 0;
  }

  .services2 .chip {
    display: inline-block;
    padding: 6px 18px;
    border-radius: 100px;
    border: 1px solid #000000;
    margin: 0 14px 14px 0;
  }
  .services2 .chip span {
    font-weight: 600;
    font-size: 14px;
    line-height: 32px;
    color: #000;
    background: none;
    -webkit-background-clip: none;
    -webkit-text-fill-color: black;
    background-clip: none;
  }

  .owpis {
    flex-direction: unset;
  }

  .services2 .our-work-process-cont h2 {
    color: #23262f;
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 12px;
    padding: 0;
  }
  .services2 .our-work-process-cont p {
    color: #23262f;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }
  .services2 .owpis-1 .col-lg-6:nth-child(2) {
    padding-left: 0;
  }
  .services2 .v2-home-work h2 {
    margin-bottom: 18px;
    padding: 0 16px;
  }

  .v2-blog .blog-crds-bdr {
    height: 732px;
  }
  .blog-post-tl {
    position: absolute;
    bottom: 0px;
    margin: 40px 0 14px 0;
  }

  .v2-blog .blog-featured-cont h3 {
    font-size: 28px;
    line-height: 36px;
  }
  .v2-blog .blogs-post-cont h3 {
    font-size: 24px;
    line-height: 36px;
  }

  .v2-blog .bth-left-p h1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  .v2-blog .bth-left-p-cont {
    margin-bottom: 75px;
  }
  /* NT changes */
  .v2-blog .v2-blog-sidebar-cont {
    border: none;
  }
  .v2-blog-sidebar-tags,
  .v2-blog-sidebar-posts,
  .v2-blog-sidebar-social {
    margin-left: 0;
  }

  .v2-blog .blog-du-card .blog-du-card-img {
    height: 320px;
  }
  /* Axe project */
  .axe-cont {
    padding: 20px 10px;
    gap: 0;
  }

  .gsis-icon-cont {
    padding: 20px 0 40px 0;
  }
}
@media only screen and (max-width: 991px) and (min-width: 769px) {
  .slogan div {
    margin: -11px 0px 0 6px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 320px) {
  .normal-header {
    padding-top: 30px;
    padding-bottom: 20px;
    margin-bottom: -92px;
    /* position: relative; */
    width: 100%;
    background-color: white;
  }
  .header-right {
    margin-top: 8px;
  }
  .button1 {
    font-size: 12px;
    line-height: 18px;
    padding: 6px 16px;
    margin-right: 8px;
    margin-left: -12px;
    height: auto;
  }
  .header-right-parent {
    top: 19px;
    right: 0px;
    mix-blend-mode: normal;
  }
  .inner-page-btn {
    margin: 0 0 0 0;
  }
  .schedule {
    background: white;
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #23262f;
    height: 41px;
    border: 1px solid #23262f;
    border-radius: 97px;
    padding: 6px 16px;
    text-decoration: none;
    transition: 0.5s linear;
    /* margin-left: 20px; */
    mix-blend-mode: exclusion !important;
  }

  .schedule:hover {
    color: #23262f;
    background: #f4f5f6;
  }

  .home2 .home-hero .container {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: auto;
    padding-bottom: 70px;
    padding-top: 450px;
  }
  .v2-home-banner-shape-img img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: auto;
  }
  .home2 .home-hero-content h1 {
    color: #23262f;
    font-size: 48px;
    font-weight: 200;
    line-height: 60px;
  }
  .home2 .home-hero-content p {
    color: #060606;
    font-size: 20px;
    line-height: 28px;
    margin-top: 24px;
  }

  .home2 .marquee-parent {
    border-left: 0;
    border-right: 0;
    display: flex;
    flex-direction: column;
    height: 90px;
    justify-content: center;
    max-width: 100%;
    overflow-x: hidden;
    position: relative;
  }
  .home2 .marquee-parent .content {
    -webkit-animation: marquee2 13s linear infinite;
    animation: marquee2 13s linear infinite;
    position: absolute;
    white-space: nowrap;
    will-change: transform;
  }
  .home2 .marquee-e {
    display: flex;
    font-size: 31.25px;
    line-height: 40px;
  }

  .v2-section2 {
    background-position: 50%;
    margin-top: 80px;
    padding: 0 0 300px 0;
  }
  .v2-home-shape-img2 img {
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
  }
  .v2-section2 .home-about-h2 {
    padding-right: 16px;
    padding-left: 16px;
  }
  .v2-section2 .extra-button-col {
    padding-right: 16px;
    padding-left: 16px;
  }

  .v2-home-about {
    padding-bottom: 80px;
  }
  .home2 .home-about .shrink {
    max-width: 91.487vw;
  }
  .home2 .home-about-h2 h2 {
    color: #fff;
    font-size: 31.25px;
    font-weight: 300;
    line-height: 40px;
  }
  .home2 .home-about-h2 h2 span {
    color: #fff;
    font-size: 31.25px;
    font-weight: 300;
    line-height: 40px;
  }
  .home2 .home-about-h2 p {
    color: #fff;
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    margin: 24px 0 0;
  }

  .slogan {
    font-size: 31.25px;
    font-weight: 300;
  }

  .slogan div {
    margin: 0 0px 0 6px;
  }

  .v2-home-about .home-about-h2 h2 {
    color: #23262f;
  }

  .v2-home-what-inner2-mobile .accordion-item {
    color: #ffffff;
    background-color: transparent;
    border-top-color: #000000;
    border-bottom-color: #000000;
  }
  .v2-home-what-inner2-mobile .accordion-item h3 {
    color: #000000;
  }
  .v2-home-what-inner2-mobile .accordion-item p {
    color: #000;
  }

  .v2-home-work-lead .home-about-h2 h2 {
    color: #23262f;
  }
  .v2-home-work-lead .home-about-h2 p {
    color: #23262f;
  }

  .home-cta .container {
    align-items: center;
    display: block;
    justify-content: space-between;
  }
  .home-cta .left {
    align-items: center;
    display: block;
    justify-content: flex-start;
  }
  .home-cta .right {
    align-items: center;
    display: block;
    justify-content: flex-end;
  }
  .home-cta .home-about-h2 {
    padding-bottom: 26px;
  }

  .home-cta .home-about-h2 h2 {
    color: #23262f;
  }

  .home-cta .home-about-h2 p {
    color: #353945;
  }

  .home-client .home-about-inner1 h2 {
    color: #23262f;
  }
  .home-client .home-about-inner1 p {
    color: #23262f;
  }

  .home-faq .accordion {
    max-width: 100%;
  }
  .home-faq .accordion-button {
    background-color: initial;
    font-size: 20px;
    font-weight: 300;
    line-height: 30px;
    padding: 24px 0;
  }
  .home-faq .accordion-body {
    font-size: 16px;
    font-weight: 300;
    line-height: 25px;
    padding: 16px 0 32px;
  }
  .home-faq .accordion-button:after {
    background-size: 20px;
    height: 20px;
    width: 20px;
  }
  .slick-prev,
  .slick-next {
    top: 100%;
  }

  .home2 footer h2 {
    font-size: 31.25px;
    font-weight: 300;
    line-height: 40px;
    padding-top: 0 !important;
  }

  .bg-gray {
    margin-bottom: 40px;
  }

  footer .footer-ff {
    display: block;
    padding: 40px 0;
  }
  footer .right {
    display: block;
  }
  footer .left {
    display: block;
  }
  .footer-ff h2 {
    padding-bottom: 20px;
  }

  footer {
    padding-top: 80px;
  }

  .footer-bg {
    background-color: transparent;
  }
  .home2 .footer-bg {
    background-color: transparent;
  }
  footer h2 {
    color: #23262f;
    padding-top: 0;
  }
  .footer-content p {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #23262f;
  }
  label {
    color: #23262f;
  }
  input,
  textarea {
    color: #23262f;
    border: 0.5px solid #23262f;
  }
  .form-control {
    color: #23262f;
    border: 1px solid #23262f;
  }
  .form-control:hover,
  .form-control:focus {
    background-color: rgb(255, 255, 255);
    color: #23262f;
  }
  .footer-content h5 {
    color: #23262f;
  }
  .footer-content .mail {
    color: #23262f;
  }
  .footer-content .number {
    color: #23262f;
  }
  .copyrights h6 {
    color: #23262f;
  }

  .services2 .process-hero .v2-home-banner-shape-img img {
    top: 0;
  }
  .services2 .process-hero h1 {
    color: #23262f;
    font-size: 38px;
    line-height: 46px;
  }
  .services2 .process-approach {
    margin-bottom: 0;
  }
  .services2 .our-approach-cont h2.line {
    display: none !important;
    justify-content: center;
  }
  .services2 .our-work-process-cont h2 {
    font-size: 26px;
    line-height: 36px;
    padding: 0 0 8px 0;
    margin-bottom: 0;
  }
  .services2 .chips-cover {
    margin-top: 0;
  }
  .services2 .our-approach-keys {
    margin-top: 0;
  }
  .services2 .owpis-1 .col-lg-6:nth-child(2) {
    padding-left: 12px;
  }
  .services2 .v2-home-work h2.line {
    position: relative;
    height: 3.313rem;
    overflow: hidden;
    display: none !important;
    justify-content: center;
  }
  .services2 .v2-home-work h2 {
    margin-bottom: 18px;
    padding: 0 16px;
  }

  .services2 .chip {
    margin: 0 10px 10px 0;
    padding: 4px 16px;
  }
  .services2 .chip span {
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
  }

  .v2-blog .blog-crds-bdr {
    height: auto;
  }

  .v2-blog .work-hero-cont h3 {
    font-size: 32px;
    line-height: 40px;
  }
  .v2-blog .blog-featured-cont h3 {
    font-size: 28px;
    line-height: 36px;
  }
  .v2-blog .blogs-post-cont h3 {
    font-size: 24px;
    line-height: 36px;
  }

  .v2-blog .bth-left-p-cont {
    margin-bottom: 75px;
  }

  .img-b-padding {
    padding-bottom: 30px;
  }
  .mahasaghar-project-text {
    font-size: 16px;
  }
  /* Axe project */
  .axe-cont {
    padding: 30px 0 0;
    gap: 0;
  }

  .gsis-icon-cont {
    padding: 0 0 40px 0;
  }
  .gsis-img7 {
    padding-bottom: 40px;
  }

  .averlon-video1 {
    padding: 60px 0;
  }
  .averlon-gif .row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }

  .para-width p {
    width: auto;
  }
}

@media only screen and (max-width: 1250px) and (min-width: 767px) {
  .v2-section2 {
    background-position: 50%;
  }
}
@media only screen and (max-width: 2560px) and (min-width: 1700px) {
  .v2-home-banner-shape-img img {
    position: absolute;
    top: 0;
    right: 0;
    width: 60%;
    height: auto;
  }
  .v2-home-shape-img2 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 47%;
    height: auto;
  }
  /* .slogan div {
        margin: -1.3vw 0px 0 6px;
    } */

  .v2-blog .blog-crds-bdr {
    height: 100%;
  }
}
@media only screen and (max-width: 2560px) and (min-width: 1700px) {
  .blog-uh-cards img {
    width: 100%;
    height: 400px;
  }
}
/* @media only screen and (max-width: 1090px) and (min-width: 1025px) {
    .blog-uh-cards img {
        width: 100%;
        height: 400px;
    }
} */

@media only screen and (max-width: 1300px) and (min-width: 1025px) {
  .blog-du-card {
    width: 100%;
  }
  .blog-uh-cards img {
    width: 100%;
    height: 400px;
  }
  .v2-blog .col-lg-8 {
    padding-right: 12px;
  }
  .v2-blog-sidebar-tags,
  .v2-blog-sidebar-posts,
  .v2-blog-sidebar-social {
    margin-left: 24px;
  }
  .blog-ww h2 {
    padding: 0px 25px 0 32px;
  }

  .v2-blog .blog-du-card .blog-du-card-img {
    height: 355px;
  }
  .sidebar-card {
    background-size: cover;
    margin: 56px 0 0 12px;
  }
}

@media only screen and (max-width: 767px) and (min-width: 426px) {
  .v2-blog .blog-content h2 {
    font-size: 24px;
    line-height: 32px;
  }
  .v2-blog .txt-outline h4 {
    font-size: 30px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 700px) and (min-width: 426px) {
  .blog-chn-card img {
    height: 530px;
  }
}

@media only screen and (max-width: 2560px) and (min-width: 2300px) {
  .slogan div {
    margin: -1.37vw 0px 0 6px;
  }
}
@media only screen and (max-width: 2299px) and (min-width: 2165px) {
  .slogan div {
    margin: -1.33vw 0px 0 6px;
  }
}
@media only screen and (max-width: 2164px) and (min-width: 1990px) {
  .slogan div {
    margin: -1.3vw 0px 0 6px;
  }
}
@media only screen and (max-width: 1991px) and (min-width: 1765px) {
  .slogan div {
    margin: -1.2vw 0px 0 6px;
  }
}
@media only screen and (max-width: 1764px) and (min-width: 1565px) {
  .slogan div {
    margin: -1vw 0px 0 6px;
  }
}
@media only screen and (max-width: 1564px) and (min-width: 1441px) {
  .slogan div {
    margin: -0.9vw 0px 0 6px;
  }
}
@media only screen and (max-width: 1440px) and (min-width: 1320px) {
  .slogan div {
    margin: -11px 0px 0 6px;
  }
}
@media only screen and (max-width: 1319px) and (min-width: 1261px) {
  .slogan div {
    margin: -9px 0px 0 6px;
  }
  .txt-break h4 {
    margin-left: -28px;
  }
}
@media only screen and (max-width: 1260px) and (min-width: 1195px) {
  .slogan div {
    margin: -12px 0px 0 6px;
  }
}
@media only screen and (max-width: 1194px) and (min-width: 1100px) {
  .slogan div {
    margin: -10px 0px 0 6px;
  }
}
@media only screen and (max-width: 1099px) and (min-width: 1025px) {
  .slogan div {
    margin: -9px 0px 0 6px;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 100%;
  padding-left: 48px !important;
  padding-right: 48px !important;
}

a {
  font-weight: 500;
  font-size: 1.25vw;
  line-height: 2.014vw;
  text-decoration-line: underline;
  color: inherit;
}

a:hover {
  color: #e6e8ec;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
}

html,
body {
  width: 100%;
  overscroll-behavior: none;
  scroll-behavior: initial !important;
  min-height: 100%;
  background-color: #ffffff;
}

body {
  font-family: "Manrope", sans-serif;
  overflow-x: hidden !important;
  background-repeat: no-repeat;
  background-size: cover;
  color: #23262f;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-future-bg-patt.svg")
    #fff;
}

main {
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-future-bg-patt.svg")
    #fff;
  overflow-x: hidden !important;
  position: relative;
}

.inifinitysvg {
  margin: 80px 0 0 0;
}

/* .svg2 {
    position: absolute;
    display: contents;
    font-weight: 300;
    font-size: 4.2vw;
    line-height: 5.556vw;
    color: #FFFFFF;
} */

.loadingh2 {
  text-align: center;
  position: relative;
  overflow: hidden;
}

.loadingh2 span {
  color: #000000;
  display: inline-block;
  margin-right: 10px;
}

h2 {
  font-weight: 300;
  font-size: 3.889vw;
  line-height: 4.444vw;
  margin: 0;
}

h2.line {
  position: relative;
  height: 4.9vw;
  overflow: hidden;
}

.slick-slide img {
  width: 40%;
}

h2.line span {
  position: absolute;
  font-weight: 300;
  font-size: 3.889vw;
  line-height: 4.444vw;
  margin: 0;
}
.sunnybee-hero-cont h2.line span {
  position: absolute;
  font-weight: 300;
  font-size: 3rem;
  line-height: 2.75rem;
  margin: 0;
}
.sunnybee-hero-cont h2.line {
  position: relative;
  height: 4rem;
  overflow: hidden;
  margin: 0;
}

label {
  font-weight: 400;
  font-size: 1.389vw;
  line-height: 1.875vw;
  color: #ffffff;
  margin-bottom: 1.785vh;
  display: block;
}

input,
textarea {
  font-weight: 400;
  font-size: 1.319vw;
  line-height: 1.806vw;
  color: #ffffff;
  margin-bottom: 40px;
  display: block;
  background-color: transparent;
  padding: 0 0 20px 0;
  /* border: 0px; */
  border: 0.5px solid #ffffff;
  width: 100%;
}

.form-control {
  /* display: block; */
  /* width: 100%; */
  /* padding: 0.375rem 0.75rem; */
  /* font-size: 1rem; */
  /* font-weight: 400; */
  /* line-height: 1.5; */
  /* color: #212529; */
  /* background-color: #fff; */
  /* background-clip: padding-box; */
  /* border: 1px solid #ced4da; */
  /* appearance: none; */
  /* border-radius: 0.375rem; */
  /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
  font-weight: 400;
  font-size: 1.3vw;
  line-height: 2.2vw;
  text-decoration: none;
  color: #e6e8ec;
  margin-bottom: 40px;
  display: block;
  background-color: transparent;
  padding: 10px 32px;
  border: 1px solid #e6e8ec;
  border-radius: 0;
  text-align: left;
  width: auto;
  transition: all 0.5s linear;
}
.errors {
  color: red;
  margin-top: -20px;
  margin-bottom: 20px;
  display: block;
}

.form-control:hover,
.form-control:focus {
  /* display: block; */
  /* width: 100%; */
  /* padding: 0.375rem 0.75rem; */
  /* font-size: 1rem; */
  /* font-weight: 400; */
  /* line-height: 1.5; */
  /* color: #212529; */
  /* background-color: #fff; */
  /* background-clip: padding-box; */
  /* border: 1px solid #ced4da; */
  /* appearance: none; */
  /* border-radius: 0.375rem; */
  /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */

  background-color: rgb(0, 0, 0);
  color: #ffffff;
}

input[type="submit"] {
  font-weight: 400;
  font-size: 1.267vw;
  line-height: 1.239vw;
  text-decoration: underline;
  color: #e6e8ec;
  margin-bottom: 40px;
  display: block;
  background-color: transparent;
  padding: 0px;
  border: 0px;
  border-bottom: 0px solid #e6e8ec;
  text-align: left;
}

input::placeholder,
textarea::placeholder {
  color: #e6e8ec;
  opacity: 0.4;
  font-size: 1vw;
  line-height: 1.806vw;
}

input:focus-visible,
textarea:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

img {
  image-rendering: -webkit-optimize-contrast !important;
}

.accordion {
  --bs-accordion-color: #fff;
  --bs-accordion-bg: transparent;
  --bs-accordion-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: #fff;
  --bs-accordion-border-width: 0.3px;
  --bs-accordion-border-radius: 0px;
  --bs-accordion-inner-border-radius: 0px;
  --bs-accordion-btn-padding-x: 16px;
  --bs-accordion-btn-padding-y: 22px;
  --bs-accordion-btn-color: #fff;
  --bs-accordion-btn-bg: transparent;
  --bs-accordion-btn-icon: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/plus.svg);
  --bs-accordion-btn-icon-width: 24px;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.35s
    cubic-bezier(0.25, 0.46, 0.45, 0.94);
  --bs-accordion-btn-active-icon: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/close.svg);
  --bs-accordion-btn-focus-border-color: #fff;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: #fff;
  --bs-accordion-active-bg: transparent;
}

.accordion-item {
  color: #ffffff;
  background-color: transparent;
  border-left: 0px solid #ffffff;
  border-right: 0px solid #ffffff;
}

.accordion-item p {
  margin: 0;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
}
.accordion-body {
  border-top: 0.3px #fff solid;
}

.mobile-lottie-acc {
  width: 50px !important;
}

.accordion-item h3 {
  margin: 0;
  font-weight: 300;
  font-size: 24px;
  line-height: 33px;
  color: #ffffff;
}

.accordion-item .col-6 svg {
  margin-top: -16px;
}

/* Header Component */

.header-left img {
  width: 140px;
  filter: invert(1);
}

.header-right-parent {
  top: 32px;
  right: 48px;
  position: fixed;
}

.normal-header {
  padding-top: 25px;
  padding-bottom: 30px;
  margin-bottom: -96px;
  position: relative;
  z-index: 99;
}

.home-about-inner2 {
  margin-top: 80px;
}

.header-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  mix-blend-mode: exclusion !important;
}

.innr-btn-align {
  margin-top: 10px;
}

.button1 {
  background: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #23262f;
  height: 41px;
  border: 1px solid #23262f;
  border-radius: 97px;
  padding: 8px 16px;
  text-decoration: none;
  transition: 0.5s linear;
  margin-left: 20px;
  mix-blend-mode: exclusion !important;
}

.button1:hover {
  font-weight: 400;
  text-align: center;
  color: #000;
  background-color: #ffffff;
  height: 41px;
  border: 1px solid #23262f;
  text-decoration: none;
}

/* ------------ Nav Menu ------------ */

.inner-page-btn {
  color: #23262f;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  border: 1px solid #23262f;
  border-radius: 100px;
  padding: 8px 24px;
  text-decoration: none;
  transition: 0.5s linear;
}
.inner-page-btn:hover {
  color: #000;
  background-color: #fff;
}
.inner-page-btn img {
  width: 4%;
  transition: 0.5s linear;
  margin: -2px 10px 0 0;
  filter: invert(1);
}
.inner-page-btn:hover img {
  filter: invert(1);
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.menu-icon:checked + label,
.menu-icon:not(:checked) + label {
  position: relative;
  top: 5px;
  right: 0;
  display: block;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 0;
  cursor: pointer;
  z-index: 10;
}
.menu-icon:checked + label:before,
.menu-icon:not(:checked) + label:before {
  position: absolute;
  content: "";
  display: block;
  width: 26px;
  height: 18px;
  z-index: 20;
  top: 0.5px;
  left: 4px;
  border-top: 1px solid #23262f;
  border-bottom: 1px solid #23262f;
  transition: border-width 600ms 400ms ease,
    top 600ms 600ms cubic-bezier(0.23, 1, 0.32, 1),
    height 600ms 600ms cubic-bezier(0.23, 1, 0.32, 1),
    background-color 200ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-icon:checked + label:after,
.menu-icon:not(:checked) + label:after {
  position: absolute;
  content: "";
  display: block;
  width: 14px;
  height: 1px;
  z-index: 20;
  top: 10px;
  right: 0px;
  background-color: #23262f;
  margin-top: -1px;
  transition: width 100ms 1750ms ease, right 100ms 1750ms ease,
    margin-top 100ms ease, transform 200ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-icon:checked + label:before {
  top: 10px;
  transform: rotate(45deg);
  height: 1px;
  background-color: #ffffff;
  border-width: 0;
  transition: border-width 600ms 340ms ease,
    top 600ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    height 600ms 300ms cubic-bezier(0.23, 1, 0.32, 1),
    background-color 200ms 500ms ease,
    transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}
.menu-icon:checked + label:after {
  width: 26px;
  margin-top: 0;
  right: 0;
  background-color: #ffffff;
  transform: rotate(-45deg);
  transition: width 100ms ease, right 100ms ease, margin-top 100ms 500ms ease,
    transform 200ms 1700ms cubic-bezier(0.23, 1, 0.32, 1);
}

.header-nav {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  z-index: 9;
  overflow: hidden;
  /* box-shadow: 0 8px 30px 0 rgba(0,0,0,0.3); */
  background-color: transprent;
  animation: border-transform 7s linear infinite;
  transition: transform 250ms 1100ms ease, background 900ms 1000ms ease,
    width 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1),
    height 650ms 400ms cubic-bezier(0.23, 1, 0.32, 1);
}
/* @keyframes border-transform{
    0%,100% { border-radius: 63% 37% 54% 46% / 55% 48% 52% 45%; } 
  14% { border-radius: 40% 60% 54% 46% / 49% 60% 40% 51%; } 
  28% { border-radius: 54% 46% 38% 62% / 49% 70% 30% 51%; } 
  42% { border-radius: 61% 39% 55% 45% / 61% 38% 62% 39%; } 
  56% { border-radius: 61% 39% 67% 33% / 70% 50% 50% 30%; } 
  70% { border-radius: 50% 50% 34% 66% / 56% 68% 32% 44%; } 
  84% { border-radius: 46% 54% 50% 50% / 35% 61% 39% 65%; } 
} */

.menu-icon:checked ~ .header-nav {
  animation-play-state: paused;
  pointer-events: all;
  top: 0;
  right: 0;
  /*   transform: translate(100%, -100%); */
  /* background: radial-gradient(at left bottom, rgb(164, 14, 97), rgb(23, 23, 23), rgb(23, 23, 23)); */
  background-color: #000;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  transition: transform 250ms 700ms ease, background 900ms 20ms ease,
    width 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1),
    height 750ms 1000ms cubic-bezier(0.23, 1, 0.32, 1);
}

.header-nav ul,
.header-nav .head-addr {
  position: relative;
  top: 50%;
  left: 0;
  display: block;
  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 6;
  text-align: left;
  transform: translateY(-50%);
  list-style: none;
}
.header-nav ul li a.nav1,
.header-nav ul li a.nav2,
.header-nav ul li a.nav3,
.header-nav ul li a.nav4 {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 0 100px;
  text-align: left;
  list-style: none;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(100px);
  transition: all 250ms linear;
}
.header-nav ul li a.nav1 {
  transition-delay: 200ms;
}
.header-nav ul li a.nav2 {
  transition-delay: 150ms;
}
.header-nav ul li a.nav3 {
  transition-delay: 100ms;
}
.header-nav ul li a.nav4 {
  transition-delay: 50ms;
}
.header-nav ul li a {
  font-weight: 400;
  font-size: 4.444vw;
  line-height: 6.042vw;
  display: inline-block;
  position: relative;
  color: #ffffff;
  transition: all 250ms linear;
  text-decoration: none;
}

.header-nav .head-addr .head-addr-cont {
  position: relative;
  display: block;
  width: 100%;
  padding: 0 0 0 200px;
  margin: 10px 0;
  text-align: left;
  list-style: none;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  transform: translateY(50px);
  transition: all 250ms linear;
}
.header-nav .head-addr .head-addr-cont:nth-child(1) {
  transition-delay: 200ms;
}
.header-nav .head-addr .head-addr-cont:nth-child(2) {
  transition-delay: 150ms;
}
.header-nav .head-addr .head-addr-cont:nth-child(3) {
  transition-delay: 100ms;
}
.header-nav .head-addr .head-addr-cont:nth-child(4) {
  transition-delay: 50ms;
}
.head-addr .head-addr-cont:nth-child(1),
.head-addr .head-addr-cont:nth-child(2) {
  padding: 0 0 40px 200px;
}
.head-addr-cont h5 {
  color: #ffffff;
  font-size: 0.972vw;
  font-weight: 500;
  line-height: 1.528vw;
}
.head-addr-cont p,
.head-addr-cont a {
  color: #e6e8ec;
  font-size: 1.25vw;
  font-weight: 400;
  line-height: 1.944vw;
  margin: 0;
  padding: 0;
}
.head-addr-cont p.head-addr-loc {
  text-decoration: underline;
}
.head-addr-cont a.head-addr-num {
  color: #e6e8ec;
  font-size: 1.944vw;
  font-weight: 400;
  line-height: 2.639vw;
  text-decoration: none;
}
.head-addr-cont .head-addr-social,
.head-addr-cont .head-addr-pp {
  color: #e6e8ec;
  font-size: 1.111vw;
  font-weight: 400;
  line-height: 2.361vw;
  padding: 0 12px 0 0;
  text-decoration: none;
}
#menu-item {
  transition: all 600ms ease;
}
#menu-items {
  transition: all 1000ms ease !important;
}
#menu-items:hover > #menu-item {
  transition: opacity 600ms ease !important;
  opacity: 0.3;
}
#menu-items:hover > #menu-item:hover {
  transition: opacity 600ms ease !important;
  opacity: 1;
}
.nav-line {
  overflow: hidden;
}
.menu-icon:checked ~ .header-nav ul li a {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 350ms ease, transform 1000ms ease;
}
.menu-icon:checked ~ .header-nav ul li a.nav1 {
  transition-delay: 480ms;
}
.menu-icon:checked ~ .header-nav ul li a.nav2 {
  transition-delay: 560ms;
}
.menu-icon:checked ~ .header-nav ul li a.nav3 {
  transition-delay: 640ms;
}
.menu-icon:checked ~ .header-nav ul li a.nav4 {
  transition-delay: 720ms;
}

.menu-icon:checked ~ .header-nav .head-addr .head-addr-cont {
  pointer-events: auto;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
  transition: opacity 350ms ease, transform 1000ms ease;
}
.menu-icon:checked ~ .header-nav .head-addr .head-addr-cont:nth-child(1) {
  transition-delay: 820ms;
}
.menu-icon:checked ~ .header-nav .head-addr .head-addr-cont:nth-child(2) {
  transition-delay: 900ms;
}
.menu-icon:checked ~ .header-nav .head-addr .head-addr-cont:nth-child(3) {
  transition-delay: 980ms;
}
.menu-icon:checked ~ .header-nav .head-addr .head-addr-cont:nth-child(4) {
  transition-delay: 1060ms;
}
.menu-icon:checked ~ .header-nav .head-addr .head-addr-cont:nth-child(5) {
  transition-delay: 1060ms;
}

.future-logo {
  position: relative;
  z-index: 9;
}
.logo {
  display: block;
  z-index: 11;
  transition: all 250ms linear;
}
.logo img {
  height: 26px;
  width: auto;
  display: block;
}

@media screen and (max-width: 767px) {
  .menu-icon:checked + label,
  .menu-icon:not(:checked) + label {
    right: 10px;
    top: 12px;
  }
  .header-right {
    flex-direction: row-reverse !important;
    align-items: center !important;
    justify-content: space-between !important;
  }
  .logo {
    left: 30px;
  }
  .header-nav {
    right: 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .header-nav ul li a {
    font-size: 46px;
    font-weight: 400;
    line-height: 62px;
  }
  .header-nav ul li a.nav1,
  .header-nav ul li a.nav2,
  .header-nav ul li a.nav3,
  .header-nav ul li a.nav4 {
    padding: 0 !important;
  }
  .header-nav .head-addr .head-addr-cont {
    padding: 0 0 0 0 !important;
    margin: 10px 0;
  }
  /* .head-addr .head-addr-cont:nth-child(1),
  .head-addr .head-addr-cont:nth-child(2) {
    padding: 0 0 12px 0 !important;
  } */

  .head-addr-cont h5 {
    font-size: 12px;
    line-height: 20px;
  }
  .head-addr-cont p,
  .head-addr-cont a {
    font-size: 18px;
    line-height: 28px;
  }
  .head-addr-cont a.head-addr-num {
    font-size: 18px;
    line-height: 28px;
  }
  .head-addr-cont .head-addr-social,
  .head-addr-cont .head-addr-pp {
    font-size: 14px;
    line-height: 30px;
  }
  .padd-resp {
    padding-bottom: 40px;
  }
  .row .padd-resp:nth-child(1) {
    padding-top: 100px;
  }
  .nav-line {
    margin: 0 0 12px 0;
  }
}

/* Header Component End */

/*----------------------------------------------------------- Homepage -----------------------------------------------------------*/

.home-cover-out {
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-future-bg-patt.svg")
    #fff;
  background-color: #ffffff;
  /* background-color: #000; */
  z-index: 9999999;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  opacity: 0;
}

.home-cover {
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-future-bg-patt.svg")
    #fff;
  background-color: #ffffff;
  z-index: 999999;
  pointer-events: none;
  top: 0;
}

.home-cover svg {
  width: 20%;
}

.home-hero {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background-color: #000;
}

.home-hero-gra {
  pointer-events: none;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(55.18deg, #F918FF -11.21%, #0267FF 110.21%, #0085FF 110.21%);
    filter: blur(158px);
    -webkit-filter: blur(158px);
    -moz-filter: blur(158px);
    -ms-filter: blur(158px);
    transform: matrix(0.8, 0.28, -0.89, 0.91, 0, 0);
    margin-top: 700px;
    opacity: 0.5; */
  background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-hero-bg.png");
  background-position-x: 50%;
  background-position-y: 0%;
  background-size: 150%;
  background-repeat: no-repeat;
}

.home-hero-content h1.line {
  height: 4.3vw;
  position: relative;
  overflow: hidden;
}

.home-hero-content h1.line span {
  position: absolute;
  font-weight: 300;
  font-size: 3.5vw;
  line-height: 3.75vw;
  color: #ffffff;
}

dd {
  font-weight: 700;
  font-size: 3vw;
  display: inline;
}

.line-dd {
  margin-top: 20px;
}

.home-hero-content .extra-button-col {
  margin: 40px 0 0 0;
}

.extra-button {
  background: white;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  transition: 0.5s linear;
  padding: 14px 20px;
  color: #23262f;
  border: 1px solid #23262f;
  border-radius: 97px;
  text-decoration: none;
  display: inline-block;
}

.extra-button:hover {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 14px 20px;
  color: #000;
  background-color: #fff !important;
  border: 1px solid #ffffff;
  border-radius: 97px;
  text-decoration: none;
}

.extra-button2:hover {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 14px 20px;
  color: #000;
  background-color: #fff;
  border: 1px solid #ffffff;
  border-radius: 97px;
  text-decoration: none;
}

.home-section2 {
  position: relative;
  /* height: 120vh; */
  overflow: hidden;
  background-color: #00000000;
}

.home-section2 .row {
  position: fixed;
  top: 0;
}

figure {
  width: 100%;
}

.home-section2 video,
.home-section2 img {
  /* aspect-ratio: 16/9; */
  width: 100%;
  height: 100%;
}

.figure2 div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.figure2 video {
  margin-bottom: 6px;
}

.home-section2 img {
  margin-bottom: 6px;
}

.video-col1 {
  flex: 0 0 46%;
  width: 46%;
  margin-left: -23%;
  position: relative;
  /* width: 26%; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
}

.video-col2 {
  flex: 0 0 46%;
  margin: 0 0.5%;
  width: 46%;
  position: relative;
  /* width: 48%; */
  padding: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.video-col3 {
  flex: 0 0 46%;
  width: 46%;
  margin-right: -23%;

  position: relative;
  /* width: 26%; */
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0;
}

.home-about {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.home-about-gra {
  opacity: 1;
  background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-about-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  z-index: 0;
  position: absolute;
  width: 40%;
  height: 600px;
  right: 0;
  top: 0;
}

.home-about-inner1 {
  padding: 220px 0;
}

.home-about-h2 {
  padding-bottom: 26px;
}

.home-about-inner-inner2 {
  display: flex;
  justify-content: left;
  align-items: center;
}

.home-about-inner2-line {
  display: inline-block;
  width: 73.3%;
  height: 1px;
  margin-left: 30px;
  border-top: 0.5px solid #fff;
  border-bottom: 0px none #000;
  text-align: right;
}

.home-about-inner2 h3 span {
  font-weight: 400;
  font-size: 16.353vw;
  line-height: 17.083vw;
}

.home-about-inner2-h3-div {
  height: 21.181vw;
  overflow: hidden;
}

.home-about-inner2-h32-div {
  overflow: hidden;
  height: 21.181vw;
}

.home-about-inner2-h32 span {
  line-height: 21.181vw;
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-infinity {
  height: 300vh;
  padding-top: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  overflow: hidden;
}

.home-infinity svg {
  position: fixed;
  top: 10%;
  bottom: 20%;
  left: 10%;
  width: 80% !important;
  height: 80% !important;
  pointer-events: none;
}

.home-what-inner1 {
  padding: 200px 0 50px 0;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.home-services {
  border-left: 0.5px solid #ffffff;
  border-right: 0.5px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  height: 50vw;
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  transform-origin: center center;
  position: relative;
  z-index: 1;
  transition-delay: 1s;
}

.home-services-hover {
  top: 0;
  left: 50%;
  width: 0px;
  height: 100%;
  position: absolute;
  background-color: #fff;
  z-index: 0;
  transform-origin: center center;
  transition: 0.6s ease;
}

.home-services .row {
  width: 100%;
}

.home-what-inner2 {
  border-top: 0.5px solid white;
}

.home-what-inner2-row {
  margin: 0;
  overflow: hidden;
  height: 50vw;
}

.home-services h3 {
  font-weight: 300;
  font-size: 2.778vw;
  line-height: 4.444vw;
  color: #ffffff;
  margin-bottom: 0px;
  position: relative;
  z-index: 1;
  transition: 0.6s ease;
}

.home-services .lottie-up {
  width: 100%;
  transform: translateY(200px);
  transition: 0.6s ease;
}

.home-services .build-lottie-up {
  width: 100%;
  transform: translateY(70px);
  transition: 0.6s ease;
}

.home-services:hover .build-lottie-up {
  width: 100%;
  transform: translateY(-20px);
  transition: 0.6s ease;
}

.home-services .design-lottie-up {
  width: 100%;
  transform: translateY(150px);
  transition: 0.6s ease;
}

.home-services:hover .design-lottie-up {
  width: 100%;
  transform: translateY(-20px);
  transition: 0.6s ease;
}

.home-services:hover .lottie-up {
  width: 100%;
  transform: translateY(-20px);
  transition: 0.6s ease;
}

.home-services p {
  font-weight: 300;
  font-size: 1.25vw;
  line-height: 2.778vw;
  margin-bottom: 0px;
  color: #fff;
  opacity: 0;
  transition: 0.6s ease;
  /* display: none; */
  transform: translateY(120px);
  transition: 0.6s ease;
  transition-delay: 0.1s;
}

.home-services:hover h3 {
  color: #000;
}

.home-services:hover .home-services-hover {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: #fff;
}

.home-services:hover img,
.home-services:hover svg {
  filter: invert(100%);
}

.home-services:hover p {
  /* display: block; */
  transform: translateY(0px);
  color: #000;
  z-index: 1;
  opacity: 1;
}

.home-clients {
  padding: 100px 0 0 0;
}

.home-clients-c {
  position: relative;
  overflow: hidden;
  padding-left: 48px;
  margin-bottom: 100px;
  padding-top: 100px;
  padding-bottom: 100px;
  transition: all 5s ease;
}

.home-clients-c-c {
  /* cursor: url('https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/drag.svg'), auto;  */
  transition: all 5s ease;
}

.ball {
  position: absolute;
  /* left: 0;
    top: 0; */
  z-index: 10;
  width: 8.23em;
  height: 8.23em;
  -webkit-perspective-origin: 50% 50%;
  perspective-origin: 50% 50%;
  -webkit-transform-origin: 50% 50%;
  -ms-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 300ms cubic-bezier(0.165, 0.84, 0.44, 1);
  pointer-events: none;
}

.home-clients-inner p {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-top: 15px;
  max-width: 318.93px;
}

.home-work {
  position: relative;
  overflow: hidden;
  /* background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/noise.svg"), linear-gradient(40deg, #000, #141416); */
  background: transparent;
}

.fixed-work-parent {
  height: 100vh;
}

.fixed-work {
  z-index: 0;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.fixed-work-cont {
  z-index: 1;
  position: relative;
}

.home-work-child {
  position: relative;
}

.home-work-child a {
  width: 100%;
  height: 100%;
  position: absolute;
}

.home-work-child img {
  width: 100%;
}

.home-work-gra {
  opacity: 0.6;
  background: linear-gradient(
    55.18deg,
    #f918ff -11.21%,
    #0267ff 110.21%,
    #0085ff 110.21%
  );
  filter: blur(158px);
  -webkit-filter: blur(158px);
  -moz-filter: blur(158px);
  -ms-filter: blur(158px);
  border-radius: 500px;
  transform: matrix(0.53, 0.76, -0.99, 0.45, 0, 0);
  z-index: -1;
  position: absolute;
  width: 30%;
  height: 50%;
  right: -10%;
}

.home-work h4 {
  font-size: 24.306vw;
  line-height: 27.778vw;
  font-weight: 400;
  text-align: center;
  margin-bottom: 100px;
}

.home-work-parent h3 {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.25rem;
  color: #ffffff;
  margin-top: 12px;
}

.home-work-parent p {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.625rem;
  color: #b1b5c4;
  margin-top: 8px;
  margin-bottom: 40px;
}

.home-work-child {
  padding-right: 20px;
}

.view-all {
  font-weight: 500;
  font-size: 2.222vw;
  line-height: 3.542vw;
  text-align: center;
  color: #e6e8ec;
  text-decoration: underline;
  padding-bottom: 100px;
  background-color: #000;
  mix-blend-mode: exclusion;
}

/*----------------------------------------------------------- Homepage End -----------------------------------------------------------*/

/* Footer Component */

footer {
  position: relative;
  z-index: 5;
  overflow: hidden;
  padding-bottom: 30px;
  /* background-image: linear-gradient(40deg, #000, #141416); */
  background: transparent;
}

.footer-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  /* background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/footerbg.png); */
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  margin-top: 0px;
}

footer h2 {
  padding-top: 100px;
  color: #ffffff;
}

.footer-content {
  margin-top: 100px;
}

footer .container {
  z-index: 1;
  position: relative;
}

.footer-content h5 {
  font-weight: 500;
  font-size: 0.972vw;
  line-height: 1.528vw;
  color: #ffffff;
}

.footer-content .mail {
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 2.014vw;
  text-decoration-line: none;
  margin-bottom: 4px;
  margin-right: 20px;
  color: #ffffff;
}

.footer-content .number {
  font-weight: 400;
  font-size: 1.25vw;
  line-height: 2.014vw;
  text-decoration: none;
  color: #ffffff;
}

.footer-content .c-block {
  margin-bottom: 50px;
}

.foot-bdr-btm {
  /* display: none; */
  /* border-bottom: 0.5px solid rgb(255, 255, 255); */
  margin: 0 0 30px 0;
}

.copyrights {
  display: flex;
  justify-content: space-between;
}

.copyrights h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #ffffff;
}

.copyrights a {
  text-decoration: none;
}

/* Footer Component End */

/* Experimental */

.cursor {
  pointer-events: none;
  position: fixed;
  padding: 0.3rem;
  background-color: #fff;
  border-radius: 50%;
  mix-blend-mode: difference;
  transition: transform 0.3s ease;
  z-index: 999;
}

.hover-this:hover ~ .cursor {
  transform: translate(-50%, -50%) scale(8);
}

.hover-this {
  transition: all 0.3s ease;
}

.ooo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

dl {
  display: inline;
  position: relative;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) linear;
  animation: mymove 4s infinite;
}

@keyframes mymove {
  0% {
    left: 0px;
  }
  50% {
    left: 50px;
  }
  100% {
    left: 0px;
  }
}

/* ------------- Process Page ------------- */

.bg-colorp {
  background-color: #000;
}

.about-hero-gra {
  pointer-events: none;
  opacity: 0.7;
  background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/about-hero-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: -1;
  position: absolute;
  width: 50%;
  height: 100%;
  right: 0;
}

.process-hero {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  /* overflow: hidden; */
  z-index: 1;
  background-color: transparent;
}

.process-hero .line {
  height: 5.556vw;
  position: relative;
  overflow: hidden;
}
.process-hero .line span {
  position: absolute;
  font-weight: 300;
  font-size: 4.444vw;
  line-height: 5.556vw;
  color: #23262f;
}

.our-process-img .col-lg-6 {
  padding: 0;
  z-index: 10;
}
.our-process-img img {
  width: 100%;
  height: 100%;
}

.chip {
  display: inline-block;
  padding: 8px 24px;
  border-radius: 100px;
  border-top: 1px solid #b28eff;
  border-bottom: 1px solid #89ccff;
  border-left: 1px solid #b28eff;
  border-right: 1px solid #89ccff;
  margin: 0 16px 16px 0;
}

.chips-cover {
  margin-top: 20px;
}

.chip span {
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.our-approach {
  position: relative;
  padding: 80px 0;
  z-index: 1;
  overflow: hidden;
}

.our-approach-keys h4 {
  font-size: 22px;
}

.our-approach-cont {
  padding: 50px 0 130px 0;
}

.our-approach-cont h2.line {
  height: 3.889vw;
}

.our-approach-cont h2 span {
  color: #23262f;
  font-size: 2.5vw;
  font-weight: 300;
  line-height: 3.889vw;
}
.our-work-process-cont h2 {
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.our-approach-gra {
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/project-hero-bg-gradient.png");
  background-position-x: 100%;
  background-position-y: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.6;
  /* transform: matrix(-0.52, 0.85, 0.85, 0.52, 0, 0); */
  z-index: -1;
  position: absolute;
  width: 800px;
  height: 1320px;
  top: -100px;
  right: 0;
  pointer-events: none;
  /* transform: matrix(-0.52, 0.85, 0.85, 0.52, 0, 0); */
  /* background: linear-gradient(55.18deg, #F918FF -11.21%, #0267FF 110.21%, #0085FF 110.21%);
    filter: blur(158px);
    -webkit-filter: blur(158px);
    -moz-filter: blur(158px);
    -ms-filter: blur(158px);
    border-radius: 296px;
    transform: matrix(0.84, 0.38, -0.65, 0.89, 0, 0); */
}
.our-work-process-gra1 {
  position: absolute;
  width: 697.17px;
  height: 546.05px;
  left: 1042.78px;
  top: 1500px;
  background: linear-gradient(
    55.18deg,
    #f918ff -11.21%,
    #0267ff 110.21%,
    #0085ff 110.21%
  );
  filter: blur(158px);
  -webkit-filter: blur(158px);
  -moz-filter: blur(158px);
  -ms-filter: blur(158px);
  border-radius: 296px;
  transform: matrix(0.8, 0.28, -0.89, 0.91, 0, 0);
  opacity: 0.6;
  z-index: -1;
}
.our-work-process-gra2 {
  position: absolute;
  width: 872.11px;
  height: 683.07px;
  left: -360.43px;
  top: 2237.06px;
  background: linear-gradient(
    55.18deg,
    #f918ff -11.21%,
    #0267ff 110.21%,
    #0085ff 110.21%
  );
  filter: blur(158px);
  -webkit-filter: blur(158px);
  -moz-filter: blur(158px);
  -ms-filter: blur(158px);
  border-radius: 296px;
  transform: matrix(0.8, 0.28, -0.89, 0.91, 0, 0);
  opacity: 0.6;
  z-index: -1;
}
.our-work-process-gra3 {
  position: absolute;
  width: 697.17px;
  height: 546.05px;
  left: 1042.78px;
  top: 2975.64px;
  background: linear-gradient(
    55.18deg,
    #f918ff -11.21%,
    #0267ff 110.21%,
    #0085ff 110.21%
  );
  filter: blur(158px);
  -webkit-filter: blur(158px);
  -moz-filter: blur(158px);
  -ms-filter: blur(158px);
  border-radius: 296px;
  transform: matrix(0.8, 0.28, -0.89, 0.91, 0, 0);
  opacity: 0.6;
  z-index: -1;
}

.our-work {
  position: relative;
}

.our-work-cont h4 {
  color: #ffffff;
  font-size: 2.778vw;
  font-weight: 300;
  line-height: 4.444vw;
}

.our-work-cont ul {
  list-style: none;
  padding: 0;
}

.our-work-cont li {
  color: #e6e8ec;
  font-size: 1.25vw;
  font-weight: 300;
  line-height: 2.778vw;
}
.bdr-ryt {
  border-right: 0.5px solid #fff;
  margin: 0 60px 0 0;
}

.our-process-cont {
  padding: 80px 0 80px 0;
}
.our-process-cont h2 {
  color: #23262f;
  font-size: 22.861vw;
  font-weight: 400;
  line-height: 24.514vw;
}
.process-inner2-h2-div,
.process-inner2-h22-div {
  position: relative;
  overflow: hidden;
  height: 24.514vw;
}

.our-process-cont h2 span {
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.our-work-process-cont h2 {
  color: #ffffff;
  font-size: 1.667vw;
  font-weight: 400;
  line-height: 4.444vw;
}
.our-work-process-cont p {
  color: #23262f;
  font-size: 1.806vw;
  font-weight: 300;
  line-height: 2.847vw;
}
.our-work-process-cont img {
  width: 100%;
}

.owpis {
  margin: 0 -12px 0 48px;
  padding: 130px 0 130px 0;
}
.owpis-1 {
  margin: 0 48px 0 -12px;
}

/* ------------- Process Page End ------------- */

/* ------------ Work Page ------------ */
.work-hero {
  position: relative;
  height: 70vh;
}
.contact-page {
  padding-top: 200px;
  overflow: hidden;
  background: black;
}
.contact-content,
.form-side {
  z-index: 2;
  position: relative;
}

.contact-hero-gra {
  pointer-events: none;
  opacity: 0.7;
  background-image: url(https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/about-hero-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 0;
  position: absolute;
  width: 50%;
  height: 150%;
  right: 0;
  top: 0;
}
/* ------------- Extra Pages ------------- */

.extra-hero {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  z-index: 0;
  background: transparent;
}

.extra-hero h1 {
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  color: #23262f;
  margin-top: 44px;
}

.extra-hero p {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  color: #23262f;
  margin-top: 24px;
}

.home-about .extra-button-col {
  margin-top: 40px;
}

.home-work h4 {
  pointer-events: none;
}

.home-work .extra-button-col {
  display: flex;
  justify-content: center;
}

.home-work .form-control {
  width: fit-content;
  margin: 80px 0;
}

.extra-hero .extra-link {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  padding: 14px 20px;
  color: #fff;
  text-decoration: none;
}

.extra-row {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
}

.p-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 70vh;
  width: 100%;
}

.p-heading h1 {
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  color: #23262f;
}

.p-heading h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-align: center;
  text-transform: uppercase;
  color: #23262f;
  margin-top: 12px;
}

.privacy {
  padding-bottom: 80px;
}

.privacy-content {
  border-top: 0.5px solid #23262f;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #23262f;
}

.privacy-content h4 {
  font-weight: 400;
  font-size: 32px;
  line-height: 64px;
  margin-bottom: 24px;
}

.privacy-content-inner1 {
  max-width: 900px;
  margin: 40px 0 40px 0;
}

.privacy-content-inner {
  max-width: 900px;
  margin: 0px 0 40px 0;
}

.blog-temp-hero {
  display: flex;
  flex-direction: column;
  padding: 200px 0;
}

.blog-temp-hero img {
  width: 100%;
}

.bth-left-p {
  position: relative;
  /* overflow: hidden; */
  display: flex;
  /* flex-direction: column; */
  /* justify-content: flex-end; */
  align-items: center;
}

.bth-left-p h1 {
  font-weight: 300;
  font-size: 2.778vw;
  line-height: 3.333vw;
}
.bth-left-p p {
  color: #b1b5c4;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
}
.bth-left-p-cont {
  margin-left: 65px;
}

.daterow {
  position: absolute;
  bottom: 0;
  padding-top: 6px;
  border-top: 0.5px solid #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.blog-author {
  text-align: right;
}

.bth-left-p h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  text-transform: uppercase;
  color: #b1b5c4;
}

.blog-content h2 {
  font-size: 32px;
  font-weight: 300;
  line-height: 44px;
  color: #ffffff;
  margin-bottom: 40px;
}
.blog-content h2 .txt-bold {
  font-weight: 700;
}
.blog-content h3 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
}

.blog-content p {
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 0;
}

.blog-list-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.blog-date {
  font-weight: 400;
  font-size: 0.972vw;
  line-height: 2.153vw;
  text-transform: uppercase;
  color: #b1b5c4;
}

.blog-ww {
  position: relative;
  background: url("./../assets/blog-ds-img-1.png") no-repeat;
  background-position: bottom;
  background-size: cover;
  margin: 60px 0;
  padding: 60px 0;
}
.blog-ww h2 {
  padding: 0 25px 0 100px;
  margin-bottom: 0;
  color: #fff !important;
}
.blog-ww p {
  padding: 0 45px 0 60px;
  border-left: 1px solid #fff;
  color: #fff !important;
}

.txt-bdr h2 {
  border-bottom: 1px solid #000;
  padding: 0 0 26px 0;
}

.txt-gradi h3 {
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-transform: uppercase;
}

.txt-outline h4 {
  font-size: 3.264vw;
  font-weight: 300;
  line-height: 4.514vw;
  background: -webkit-linear-gradient(180deg, #b28eff 100%, #89ccff 0%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;
  background-clip: text;
  color: black;
}

.blog-sf {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.blog-sf img {
  width: 75%;
}

.blog-du-imgcont {
  position: relative;
  padding: 60px 0;
}

.blog-du-imgcont h2 {
  font-size: 24px;
  line-height: 40px;
  padding: 0 20px 0 0;
}
.blog-du-imgcont img {
  width: 92%;
}
.blog-du-imgcont .col-md-6:nth-child(2) {
  padding: 0 0 0 32px;
}

.txt-bdr-du h2 {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  margin: 40px 0;
  padding: 26px 10px 26px 0;
}

.quote-bg {
  position: relative;
  background: url("./../assets/blog-du-bgi-1.png") no-repeat;
  background-size: cover;
  margin: 50px 0;
  padding: 42px 0;
}
.quote-bg h2,
.quote-bg p {
  margin-bottom: 0;
  padding: 0 100px 0 110px;
}

.quote-bg .blog-du-img3 {
  position: absolute;
  top: -27px;
  left: 27px;
  width: 6%;
}

.blog-du-card {
  position: relative;
  padding-bottom: 24px;
  overflow: hidden;
}
.blog-du-cardcont {
  position: absolute;
  top: 0;
  padding: 40px 30px 0 30px;
  color: #fff;
}
.blog-du-cardcont .txt-outline h4 {
  font-size: 3.264vw;
  font-weight: 300;
  line-height: 4.514vw;
  background: -webkit-linear-gradient(180deg, #b28eff 100%, #89ccff 0%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;
  background-clip: text;
  color: rgb(255, 255, 255);
}
.blog-du-cardcont p {
  padding: 0 32px 0 0;
  color: #fff !important;
}

.blog-du-img8-9 img {
  width: 100%;
  padding: 0 0 40px 0;
}

.blog-du-coux h2 {
  color: #ffffff;
  font-size: 32px;
  font-weight: 300;
  line-height: 44px;
  padding: 0 0 26px 0;
  border-bottom: 1px solid #fff;
}
.blog-du-coux h2 span {
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 700;
}

.blog-du-coux-inner img {
  width: 100%;
  padding: 0 0 26px 0;
}
.blog-du-coux-inner h2 {
  margin-bottom: 20px;
}
.blog-du-coux-inner a {
  color: #e6e8ec;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #fff;
  border-radius: 52px;
  margin-bottom: 32px;
}
.blog-du-img11 {
  margin-top: 32px;
}

.blog-line-bdr {
  border-bottom: 1px solid #fff;
  margin: 30px 0;
}
.marg-tb {
  margin: 40px 0;
}
.marg-b {
  margin-bottom: 48px;
}
.marg-b1 {
  margin-bottom: 16px;
}

.blog-rapd-cardcont {
  position: relative;
  top: 0;
  padding: 40px 25px;
}
.blog-rapd-cardcont .txt-outline h4 {
  font-size: 3.264vw;
  font-weight: 300;
  line-height: 3.514vw;
  background: -webkit-linear-gradient(180deg, #b28eff 100%, #89ccff 0%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;
  background-clip: text;
  color: rgb(255, 255, 255);
}
.blog-rapd-cardcont p {
  font-size: 15px;
  padding: 0 0 0 0;
}

.blog-rapd-card1 {
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img-3.png")
    no-repeat;
  background-size: cover;
  background-position: top left;
  width: 100%;
  height: 100%;
}
.blog-rapd-card2 {
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img-4.png")
    no-repeat;
  background-size: cover;
  background-position: top left;
  width: 100%;
  height: 100%;
}
.blog-rapd-card3 {
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-rapd-img-5.png")
    no-repeat;
  background-size: cover;
  background-position: top left;
  width: 100%;
  height: 100%;
}

.blog-smbi-card1 {
  color: #000 !important;
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-smbi-img-3.png")
    no-repeat #fff;
  background-size: cover;
  background-position: top left;
  width: 100%;
  height: 100%;
}
.blog-smbi-card2 {
  color: #000 !important;
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-smbi-img-4.png")
    no-repeat #fff;
  background-size: cover;
  background-position: top left;
  width: 100%;
  height: 100%;
}
.blog-smbi-card3 {
  color: #000 !important;
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-smbi-img-5.png")
    no-repeat #fff;
  background-size: cover;
  background-position: top left;
  width: 100%;
  height: 100%;
}

.blog-smbi-card4 {
  color: #000 !important;
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-smbi-img-6.png")
    no-repeat #fff;
  background-size: cover;
  background-position: top left;
  width: 100%;
  height: 100%;
}

.blog-smbi-card1 h4:nth-child(1),
.blog-smbi-card2 h4:nth-child(1),
.blog-smbi-card3 h4:nth-child(1),
.blog-smbi-card4 h4:nth-child(1) {
  color: #000 !important;
}

.blog-temp-other {
  padding: 80px 0;
}

.blog-temp-other h4 {
  font-size: 2.778vw;
  line-height: 4.444vw;
  font-weight: 400;
  margin: 50px 0 10px 0;
}

.blogs-post {
  position: relative;
  padding: 0 0 100px 0;
  z-index: 1;
}
.blogs-post-cont {
  padding-right: 12px !important;
}

.blogs-post-cont .home-work-child {
  padding: 0;
}

.blog-crds-bdr {
  position: relative;
  /* height: 860px; */
  border: 0.5px solid #ffffff;
  margin: 40px 0 0 0;
  padding: 14px;
}
.blog-crds-bdr h3 {
  height: 150px;
  margin-bottom: 60px;
}

.blog-featured-img img {
  width: 100%;
  padding: 0 50px 0 0;
}
.blog-featured-cont h3 {
  color: #e6e8ec;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
}
.blog-featured-cont p {
  color: #b1b5c4;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}
.blog-featured-tl {
  position: absolute;
  bottom: 0;
  margin: 160px 0 40px 0;
}
.blogs-post-cont h3 {
  color: #e6e8ec;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}
.blogs-post-cont p {
  color: #b1b5c4 !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  margin-bottom: 0 !important;
}
.blog-post-tl {
  position: absolute;
  bottom: 0;
  margin: 40px 0 14px 0;
}

.blog-chip {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 100px;
  border-top: 1px solid #b28eff;
  border-bottom: 1px solid #89ccff;
  border-left: 1px solid #b28eff;
  border-right: 1px solid #89ccff;
  margin: 0 12px 16px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.blog-chips-cover {
  margin-top: 20px;
}

.blog-chip span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.blog-uh-bg {
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg.png")
    no-repeat #fff;
  background-size: cover;
  background-position: top right;
  width: 100%;
  /* height: 100%; */
  padding: 2.778vw 6.944vw;
  text-align: center;
}
.blog-uh-bg img,
.blog-uh-bg1 img,
.blog-uh-bg2 img,
.blog-uh-bg3 img,
.blog-uh-bg4 img {
  width: 100%;
}
.blog-uh-bg1 {
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg1.png")
    no-repeat #fff;
  background-size: cover;
  background-position: center left;
  width: 100%;
  /* height: 100%; */
  padding: 0 6.944vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blog-uh-bg1 .blog-uh-bg-cont {
  padding: 0 4.944vw 0 6.944vw;
}
.blog-uh-bg2 {
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg1.png")
    no-repeat #fff;
  background-size: cover;
  background-position: center left;
  width: 100%;
  /* height: 100%; */
  padding: 2.778vw 6.944vw 0 6.944vw;
  display: flex;
}
.blog-uh-bg2 .blog-uh-bg-cont {
  padding: 6.944vw 3.011vw 6.944vw 6.111vw;
}
.blog-uh-bg3 {
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg1.png")
    no-repeat #fff;
  background-size: cover;
  background-position: center left;
  width: 100%;
  /* height: 100%; */
  padding: 0 6.944vw 2.778vw 6.944vw;
  display: flex;
}
.blog-uh-bg3 .blog-uh-bg-cont {
  padding: 6.944vw 3.011vw 6.944vw 6.111vw;
}
.blog-uh-bg4 {
  position: relative;
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/blog-uh-img-bg1.png")
    no-repeat #fff;
  background-size: cover;
  background-position: center left;
  width: 100%;
  /* height: 100%; */
  padding: 2.778vw 6.944vw;
  display: flex;
}
.blog-uh-bg4 .blog-uh-bg-cont {
  padding: 6.944vw 0 6.944vw 2.778vw;
}

/* .blog-uh-bg img {
    width: 80%;
    text-align: center;
} */
.blog-uh-bg-cont p {
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;
  font-style: italic;
  line-height: 19px;
  padding-top: 1.111vw;
}

.blog-uh-cards {
  color: #000;
}
.blog-uh-cards .blog-du-cardcont .txt-outline h4 {
  color: #000;
  background: none;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;
}
.blog-uh-cards img {
  width: 100% !important;
  height: 520px;
}
.blog-uh-cards h4,
.blog-uh-cards p {
  color: #23262f !important;
}

.blog-uh-cards h4:nth-child(1) {
  font-size: 46px !important;
  line-height: 65px !important;
  font-weight: 400 !important;
}

/* .blog-num {
    font-size: 46px !important;
    line-height: 65px !importantwork-hero-cont;
} */
/* ------------- Extra Pages End ------------- */

/* ------------ Work page ------------ */

.work-hero {
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 1;
}
.work-hero-cont {
  margin: 26px 0 -26px 0;
}
.work-hero-cont h3 {
  color: #e6e8ec;
  font-size: 3.333vw;
  font-weight: 400;
  line-height: 4.444vw;
}
.work-hero-cont-p {
  font-weight: 300;
  font-size: 1.563rem;
  line-height: 2rem;
  margin-top: 24px;
}
.work-project {
  position: relative;
  z-index: 10;
}
.work-project .nav-tabs {
  margin: 0 0 58px 0;
}

.nav-tabs .nav-link {
  color: #ffffff;
  font-size: 1.111vw;
  font-weight: 400;
  line-height: 1.528vw;
  margin: 0 46px 0 0;
  padding: 16px 0;
  border-radius: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: #ffffff;
  font-size: 1.111vw;
  font-weight: 400;
  line-height: 1.528vw;
  background-color: transparent;
  border-top: 1px solid #fff !important;
  border-color: transparent;
  border-radius: 0;
  padding: 16px 0;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  isolation: isolate;
  border-top: 1px solid #fff !important;
  border-radius: 0;
  border-color: transparent;
}
/* ------------ Work Page End ------------ */

/* ------------ Sunnybee page ------------ */

.sunnybee-cover {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff;
  /* background-color: #000; */
  z-index: 999999999999;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  pointer-events: none;
}

.sunnybee-hero {
  height: 100vh;
  /* background-color: #ffffff; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
}
.sunnybee-hero-cont {
  margin: 32px 0 0px 0;
}
.sunnybee-hero-cont h2 {
  color: #23262f;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.375rem;
  margin-bottom: 18px;
}
.sunnybee-hero-cont h3 {
  color: #23262f;
  font-size: 3.333vw;
  font-weight: 400;
  line-height: 4.444vw;
}
.sunnybee-hero-cont ul {
  list-style: none;
  padding: 0;
  text-align: right;
}
.sunnybee-hero-cont li {
  color: #23262f;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 2rem;
  text-transform: uppercase;
}
.sunnybee-hero-listitem {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sunnybee-video1 video,
.sunnybee-video1 img {
  width: 100%;
  z-index: 10;
  position: relative;
}

.sunnybee-about {
  position: relative;
  padding: 80px 0;
}
.sunnybee-about-cont h4 {
  color: #23262f;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
  margin-bottom: 0;
}
.sunnybee-about-cont p {
  color: #23262f;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.625rem;
  padding: 0 20px 0 0;
}
.sunnybee-about-cont.txt-white h4,
.sunnybee-about-cont.txt-white p {
  color: #fff;
}
.txt-white h4,
.txt-white p {
  color: #fff;
}
.sunnybee-about-cont.txt-black h4 .sunnybee-about-cont.txt-black p {
  color: #000;
}
.txt-black h4 .txt-black p {
  color: #000;
}

.sunnybee-gra1 {
  opacity: 0.6;
  /* transform: matrix(-0.52, 0.85, 0.85, 0.52, 0, 0); */
  background: linear-gradient(
    55.18deg,
    #f918ff -11.21%,
    #0267ff 110.21%,
    #0085ff 110.21%
  );
  filter: blur(158px);
  -webkit-filter: blur(158px);
  -moz-filter: blur(158px);
  -ms-filter: blur(158px);
  border-radius: 296px;
  transform: matrix(0.84, 0.38, -0.65, 0.89, 0, 0);
  z-index: -1;
  position: absolute;
  width: 777.38px;
  height: 617.33px;
  top: 0;
  left: -25%;
}
.sunnybee-about-img video {
  max-width: 100%;
}
.sunnybee-about-img img {
  width: 100%;
  height: 100%;
}
.sunnybee-about-img4 {
  padding-top: 64px;
}

.sunnybee-ai {
  position: relative;
  padding: 100px 0;
}
.sunnybee-ai-cont img {
  width: 100%;
  padding: 0 80px;
}
.sunnybee-ai-cont h4 {
  color: #23262f;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
}
.sunnybee-ai-cont p {
  color: #23262f;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  padding: 0 12px 0 0;
}

/* .sunnybee-qe img {
    width: 100%;
} */
.sunnybee-qe-video2 video {
  width: 100%;
  padding-top: 100px;
}
.sunnybee-video4 {
  padding-top: 40px;
}
.sunnybee-video5-6 {
  padding-top: 40px;
}
.sunnybee-video5-6 video {
  width: 100%;
  height: 100%;
}

.sunnybee-qep {
  position: relative;
  background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/sunnybee-img-bg-1.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0 0 0;
}
.sunnybee-qep-cont h4 {
  color: #ffffff;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.5rem;
  text-align: center;
}
.sunnybee-qep-cont p {
  color: #ffffff;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.625rem;
  text-align: center;
}
.sunnybee-qep-img {
  position: relative;
  padding: 60px 0 0 0;
  text-align: center;
}
.sunnybee-qep-img p {
  color: #ffffff;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.125rem;
  position: absolute;
  bottom: 85px;
  right: 0;
  left: 25.5%;
  text-align: left;
}
/* .sunnybee-qep-img img:nth-child(2) {
    position: relative;
    margin: 0 0 0 -55px;
} */
.sunnybee-qep-img img:nth-child(2) {
  position: relative;
  margin: -70px -70px 0 0;
  z-index: 1;
  box-shadow: 0 8px 30px 0 rgba(0, 0, 0, 0.3);
}
.sunnybee-qep-img img:nth-child(4) {
  position: relative;
  margin: 0 0 -70px -55px;
}

.sunnybee-work {
  position: relative;
  overflow: hidden;
  /* background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/noise.svg"), linear-gradient(40deg, #000, #141416); */
  padding: 100px 0;
}

.sunnybee-work h4 {
  font-size: 2.5rem;
  line-height: 3.75rem;
  font-weight: 400;
  margin: 50px 0;
}

.bdr-top {
  border-top: 0.5px solid #23262f;
}
/* ------------ Sunnybee page ------------ */

/* ------------ Savittr page ------------ */

.savittr-creative {
  position: relative;
}
.savittr-creative-bg {
  background-color: #000;
}
.savittr-creative-das {
  position: relative;
  margin: 80px 0;
  padding: 40px 0;
}
.savittr-creative-das h4 {
  color: #f4f5f6;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.25rem;
}
.savittr-creative-das p {
  color: #f4f5f6;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  padding: 0 52px 0 0;
}
.savittr-creative-das img {
  width: 100%;
}

.savittr-img3-4 img {
  width: 100%;
  height: 100%;
}

.savittr-creative-intra {
  position: relative;
  padding: 100px 0;
}
.savittr-creative-intra h4 {
  color: #23262f;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
  margin: 0 0 16px 0;
  padding: 0 60px 0 0;
}
.savittr-creative-intra p {
  color: #23262f;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  padding: 0 80px 0 0;
}
.savittr-creative-intra img {
  width: 100%;
}

.savittr-creative-contress {
  position: relative;
  padding: 0 0 100px 0;
}
.savittr-creative-contress h4 {
  color: #23262f;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
  padding: 0 60px 0 0;
}
.savittr-creative-contress p {
  color: #23262f;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
  padding: 0 60px 0 0;
}

.savittr-creative-contress img {
  width: 100%;
}
.savittr-img8 img {
  width: 100%;
}
.savittr-video1 video,
.savittr-video2 video,
.savittr-video3 video,
.savittr-video4 video {
  width: 100%;
}
/* ------------ Savittr page End ------------ */

/* ------------ Dealsplus Website & Product page ------------ */

.dealsplusW-sitecore {
  position: relative;
  padding: 100px 0 0 0;
}
.dealsplusW-img3-4-5 img {
  width: 100%;
  padding: 0 0 60px 0;
}

.dealsplusW-video1 video {
  width: 100%;
}
.dealsplusW-video2 video {
  width: 100%;
  padding: 0 0 60px 0;
}

.dealsplusP-video1 video {
  width: 100%;
}

.dealsplusP-img4-5 img {
  width: 100%;
}
.dealsplusP-img4 img {
  padding: 0 0 60px 0;
}
.dealsplusP-img11 img {
  width: 100%;
}
.dealsplusP-img6 img {
  padding: 0 0 60px 0;
}

.dealsplusP-img {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
}
.dealsplusP-img .img6 {
  position: relative;
  margin: -400px -125px 0 0px;
}
.dealsplusP-img .img7 {
  position: relative;
  text-align: center;
  z-index: 1;
}
.dealsplusP-img .img8 {
  position: relative;
  margin: -3px -145px -575px -315px;
}
.dealsplusP-img .img9 {
  position: relative;
  margin: -390px 0px 0px -116px;
}
.dealsplusP-img .img10 {
  position: relative;
  margin: 15px 0px 0 -465px;
}

.bg-gray {
  margin-bottom: 60px;
}
.mb-hc {
  margin-bottom: 60px;
}
/* ------------ Dealsplus Website & Product page End ------------ */

/* ------------ Fut bank Page ------------ */

.futbank-ebp p {
  padding: 0 30px 0 0;
}
.futbank-img4 img,
.futbank-img5 img {
  width: 100%;
}

.futbank-rd {
  position: relative;
  padding: 100px 0;
}
.futbank-rd h4 {
  color: #23262f;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.25rem;
}
.futbank-rd p {
  color: #23262f;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.625rem;
}
.futbank-video-img video {
  width: 100%;
}
.futbank-video-img img {
  height: fit-content;
  aspect-ratio: 731.5/506.19;
}
.futbank-video7 video {
  width: 100%;
}

.futbank-cont-resp h4 {
  font-size: 22px !important;
  line-height: 30px !important;
}

/* ------------ Fut bank Page End ------------ */

/* ------------ Drapesanddreams Page ------------ */

.dandd-about-cont h4 {
  color: #f4f5f6;
  font-size: 1.667vw;
  font-weight: 600;
  line-height: 2.5vw;
}

.dandd-img2 img,
.dandd-img5-6-7 img,
.dandd-img5-6-7 video {
  width: 100%;
  padding: 0 0 80px 0;
}
/* ------------ Drapesanddreams Page End ------------ */

/* ------------ Authorcafe Page ------------ */

.authorcafe-video-img img {
  width: 100%;
}
.authorcafe-video3 img {
  width: 100%;
}

.authorcafe-video1 video,
.authorcafe-video3 video {
  width: 100%;
}
.authorcafe-video1 video {
  padding: 0 0 60px 0;
}
.authorcafe-img6 img {
  padding: 60px 0 0 0;
}

.authorcafe-img {
  /* background: url('../assets/authorcafe-img-bg.png') no-repeat; */
  /* background-position: 50% 35%; */
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 0 0 0 0;
}
/* .authorcafe-img .img8 {
    position: relative;
    margin: -500px -316px 0 0;
}
.authorcafe-img .img9 {
    position: relative;
    text-align: center;
    z-index: 1;
}
.authorcafe-img .img10 {
    position: relative;
    margin: -390px 0px 0px -265px;
    z-index: 1;
}
.authorcafe-img .img11 {
    position: relative;
    margin: 510px -230px 0 -252px;
    z-index: 2;
}
.authorcafe-img .img12 {
    position: relative;
    margin: 530px 0px 0 -595px;
} */
/* ------------ Authorcafe Page End ------------ */

/* ------------ Ewallet Page ------------ */

.ewallet-extra-text {
  margin-top: 100px;
}
.ewallet-extra-text h4 {
  font-size: 2rem;
  line-height: 2.5rem;
}
.e-wallet-w {
  display: flex;
  justify-content: center;
}

.e-wallet-w img {
  width: 70%;
  margin-bottom: -220px;
}
.e-wallet-w2 {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  background-color: #23262f;
  height: 85vh;
}

.e-wallet-w2 img {
  width: 60%;
  margin-bottom: -120px;
}

/* ------------ Ewallet Page End ------------ */

.tb-bg-sec {
  position: relative;
  background-color: #23262f;
  padding: 40px 0 200px 0;
}
.tb-bg-sec img {
  padding: 0 0 50px 0;
}
.tb-img-8 img {
  margin: -150px 0 0 0;
}

/* ------------ TNQ Page ------------ */

.tnq-img2-3 {
  position: relative;
  margin-bottom: 120px;
}
.tnq-img3 {
  position: absolute;
  top: 50px;
  right: 0;
}
.tnq-img2 img,
.tnq-img3 img {
  height: auto;
}
.tnq-img2 h4,
.tnq-img3 h4 {
  margin: 12px 0 0 0;
}
.tnq-bg {
  position: relative;
  margin: 0 0 100px 0;
}
.tnq-bg-video {
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.tnq-img4 img {
  position: absolute;
  bottom: -46%;
  left: 0;
  height: auto;
  pointer-events: none;
}
.tnq-bg-video video {
  width: 100%;
}

.tnq-video-3 {
  padding-bottom: 40px;
}

/* ------------ TNQ Page End ------------ */

/* ------------ Careers Landing Page ------------ */

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 11vw;
  overflow-x: hidden;
  border-top: 1px solid #b28eff;
  border-bottom: 1px solid #b28eff;
  z-index: 9;
}

.marquee .content {
  font-size: 7.778vw;
  font-weight: 400;
  line-height: 10.556vw;
  text-align: center;
  text-transform: uppercase;
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.marquee-dot {
  margin-top: -22px;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 32s linear infinite;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

.our-principle {
  position: relative;
  padding: 100px 0;
  text-align: center;
}

.our-principle-cont h3 {
  position: relative;
  color: #e6e8ec;
  font-size: 2.778vw;
  font-weight: 400;
  height: 4.444vw;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.our-principle-cont h2 {
  color: #ffffff;
  font-size: 13.472vw;
  font-weight: 400;
  line-height: 12.778vw;
}
.career-ll-h2 {
  position: relative;
  overflow: hidden;
  height: 12.778vw;
}
.our-principle-cont h2 .gradi-txt {
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.our-principle-cont h2 .gradi-txt1 {
  background: linear-gradient(180deg, #b28eff 0%, #89ccff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
.our-principle-cont p {
  color: #ffffff;
  font-size: 1.25vw;
  font-weight: 500;
  line-height: 2.222vw;
  margin-top: 52px;
}

.career-ll-cont {
  position: relative;
  /* min-height: 100vh;
    display: grid;
    align-content: center; */
  margin: 120px 0;
  z-index: 1;
}

.careers-open-position {
  position: relative;
  padding: 100px 0;
}

.open-position-card {
  position: relative;
  display: block;
  border: 1px solid #fff;
  margin: 52px;
  padding: 40px 30px;
  text-decoration: none;
}
.open-position-card-cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.open-position-card-cont h4 {
  color: #e6e8ec;
  font-size: 2.222vw;
  font-weight: 400;
  line-height: 2.778vw;
}
.open-position-card-cont p {
  color: #b1b5c4;
  font-size: 0.833vw;
  font-weight: 400;
  line-height: 2.222vw;
  text-transform: uppercase;
  margin-bottom: 0;
}
.open-position-card-cont a {
  color: #ffffff;
  font-size: 1.111vw;
  font-weight: 400;
  line-height: 1.528vw;
  text-align: center;
  border: 1px solid #ffffff;
  border-radius: 97px;
  padding: 12px 26px;
  text-decoration: none;
}

.our-principle-gra {
  background: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/project-hero-bg-gradient-1.png");
  background-position-x: 100%;
  background-position-y: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  /* opacity: 0.3; */
  /* transform: matrix(-0.52, 0.85, 0.85, 0.52, 0, 0); */
  z-index: -1;
  position: absolute;
  width: 1190.4px;
  height: 992.06px;
  left: 300.44px;
  top: -245px;
  pointer-events: none;
  /* transform: matrix(-0.52, 0.85, 0.85, 0.52, 0, 0); */
  /* background: linear-gradient(55.18deg, #F918FF -11.21%, #0267FF 110.21%, #0085FF 110.21%);
    filter: blur(158px);
    -webkit-filter: blur(158px);
    -moz-filter: blur(158px);
    -ms-filter: blur(158px);
    border-radius: 296px;
    transform: matrix(0.84, 0.38, -0.65, 0.89, 0, 0); */
}

/* --------- UIUX Designer */

.careers-rq {
  position: relative;
  padding: 50px 0 0 0;
}
.careers-rq-cont {
  position: relative;
  padding: 100px 0 0 0;
}
.careers-rq-cont h3 {
  color: #ffffff;
  font-size: 2.222vw;
  font-weight: 500;
  line-height: 2.083vw;
}

.careers-rq-cont ul {
  margin-bottom: 0;
}
.careers-rq-cont li {
  color: #ffffff;
  font-size: 1.111vw;
  font-weight: 500;
  line-height: 1.667vw;
  margin-bottom: 18px;
}

.careers-form {
  position: relative;
  z-index: 5;
  overflow: hidden;
  padding: 0 0 80px 0;
  background: transparent;
}
.careers-form-cont {
  padding-top: 200px;
}

[type="file"] {
  height: 0;
  width: 0;
}
[type="file"] + label {
  color: #b1b5c4;
  background: transparent;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1.389vw;
  font-weight: 400;
  line-height: 1.806vw;
  margin-right: 16px;
  margin-bottom: 12px;
  position: relative;
  text-decoration: underline;
}
.upr {
  color: #b1b5c4;
  font-size: 0.833vw;
  font-weight: 400;
  line-height: 1.111vw;
  text-decoration-line: underline;
  margin-bottom: 40px;
  padding: 0 0 20px 0;
  border: 0px;
  border-bottom: 0.5px solid #fff;
}
.input-brows {
  margin: 0;
  padding: 0;
}

.careers-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/careers-rgb.png");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  margin-top: 0px;
}

.careers-form-cont h3 {
  position: relative;
  color: #ffffff;
  font-size: 2.222vw;
  font-weight: 500;
  line-height: 2.083vw;
  z-index: 2;
}

/* --------- UIUX Designer */

/* ------------ Careers Landing Page End ------------ */

/* Comingsoon */

.comingsoon {
  z-index: 9999999999999999999;
}

/* Landing */

.home-testimonials-inner {
  max-width: 60%;
  margin-left: 10px;
  margin-top: 80px;
}

.home-testimonials-inner h3 {
  font-weight: 300;
  font-size: 1.667vw;
  line-height: 2.569vw;
  color: #e6e8ec;
}

.home-testimonials-inner h4 {
  font-weight: 700;
  font-size: 1.25vw;
  line-height: 2.15vw;
  color: #ffffff;
}

.home-testimonials-inner h5 {
  font-weight: 300;
  font-size: 0.903vw;
  line-height: 0.903vw;
  color: #ffffff;
  margin-bottom: 40px;
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  z-index: 10;
  top: 100%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, 20%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:before,
.slick-next:before {
  width: 51px;
  height: 51px;
  display: block;
  opacity: 1;
}

.slick-prev:before {
  content: url("data:image/svg+xml,%3Csvg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.9204 25.8447H13.9653M13.9653 25.8447L24.2907 15.5193M13.9653 25.8447L24.2907 36.1701' stroke='white' stroke-width='0.5'/%3E%3Crect x='0.942871' y='0.844727' width='50' height='50' rx='25' stroke='white'/%3E%3C/svg%3E%0A");
}
.slick-next:before {
  content: url("data:image/svg+xml,%3Csvg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.9653 25.8447H37.9204M37.9204 25.8447L27.595 15.5193M37.9204 25.8447L27.595 36.1701' stroke='white' stroke-width='0.5'/%3E%3Crect x='-0.5' y='0.5' width='50' height='50' rx='25' transform='matrix(-1 0 0 1 50.4429 0.344727)' stroke='white'/%3E%3C/svg%3E%0A");
  right: 0px;
}

.slick-prev {
  left: 10px !important;
}

.slick-next {
  right: 0 !important;
  left: 80px;
}

.landing-cta {
  padding: 80px 0;
  border-bottom: 0.5px solid #ffffff;
  position: relative;
}

.landing-cta p {
  padding-top: 20px;
}

.landing-button-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.radios-budget {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 780px;
}

.radios-budget .form-check {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 16px;
  padding: 0px;
}

.radios-budget label {
  display: inline-block;
  padding: 8px 24px;
  border-radius: 100px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
}

.radios-budget input[type="radio"] {
  display: none;
}

.radios-budget input[type="radio"]:checked + label {
  display: inline-block;
  padding: 8px 24px;
  border-radius: 100px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  font-weight: 700;
  font-size: 14px;
  line-height: 30px;
  background: white;
  color: #000 !important;
}

.landing-hero {
  height: 102vh !important;
  margin-top: -1vh;
}

.landing-hero-dec {
  position: absolute;
  right: 100px;
  bottom: 0;
  top: 10%;
  width: 40%;
}

.landing-hero .container {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.whatsapp-landing {
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 100;
}

.landing-hero-gra {
  pointer-events: none;
  z-index: -1;
  position: absolute;
  width: 100%;
  height: 100%;
  /* background: linear-gradient(55.18deg, #F918FF -11.21%, #0267FF 110.21%, #0085FF 110.21%);
    filter: blur(158px);
    -webkit-filter: blur(158px);
    -moz-filter: blur(158px);
    -ms-filter: blur(158px);
    transform: matrix(0.8, 0.28, -0.89, 0.91, 0, 0);
    margin-top: 700px;
    opacity: 0.5; */
  background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/home-hero-bg.png");
  background-position-x: 20%;
  background-position-y: 110%;
  background-size: 150%;
  background-repeat: no-repeat;
}

.blog-chn-card img {
  height: 500px;
}

.blog-adn-card {
  height: 400px;
}

.blog-adn-card p {
  padding-right: 120px;
  font-weight: 300;
  font-size: 17px;
  line-height: 40px;
}

.schedule {
  color: #f4f5f6;
  background: #23262f;
  border-radius: 97px;
  text-decoration: none;
}

.schedule:hover {
  color: #f4f5f6;
  background: #23262f;
}

.padd-top {
  padding: 80px 0 100px 0;
}

/*----------------------------------------------------------- Home2 -----------------------------------------------------------*/

/* .home2{
    background: linear-gradient(159deg, #000 0%, rgba(20, 20, 22, 0.92) 100%);
} */

.home2 .home-hero {
  height: auto;
  min-height: 100vh;
  z-index: 1;
  background-color: transparent;
}

.home2 .home-hero .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* min-height: 100vh; */
  min-height: 66.667vw; /* video height 55.667vw */
}

.home2 .home-hero-content {
  /* margin-bottom: -2.097vw; */
  width: 100%;
}

.home2 .home-hero-gra {
  pointer-events: none;
  z-index: -1;
  width: 72.569vw;
  height: 72.569vw;
  position: absolute;
  right: 0;
  top: -10vw;
  background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/h2-hbg2.png");
  background-size: 100%;
  background-repeat: no-repeat;
}

.v2-home-banner-shape-img img {
  position: absolute;
  top: 0;
  right: 0;
  width: 60%;
  height: auto;
}
.v2-home-banner-video video {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
}

.home2 .home-hero-content h1.line {
  height: 5.2rem;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.home2 .home-hero-content h1.line span {
  position: absolute;
  font-weight: 300;
  font-size: 4.5rem;
  line-height: 4.625rem;
  color: #23262f;
}

.home2 .home-hero-content p {
  font-weight: 300;
  font-size: 1.563rem;
  line-height: 2rem;
  margin-top: 24px;
}

.home-hero-content .extra-button-col {
  margin: 24px 0 0 0;
}

.home2 .extra-button {
  background: white;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  transition: 0.5s linear;
  padding: 14px 20px;
  color: #23262f;
  border: 1px solid #23262f;
  border-radius: 97px;
  text-decoration: none;
  display: inline-block;
}

.extra-button:hover {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 14px 20px;
  color: #000;
  background-color: #fff;
  border: 1px solid #23262f;
  border-radius: 97px;
  text-decoration: none;
}

.home2 .marquee-parent {
  border: 1px solid #d6d6d6;
  border-left: 0px;
  border-right: 0px;
  position: relative;
  max-width: 100%;
  height: 12.014vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home2 .marquee-e {
  line-height: 5.556vw;
  font-size: 4.417vw;
  display: flex;
}

.home2 .marquee-e span {
  display: inline-block;
  align-self: center;
  width: 1.709vw;
  height: 1.709vw;
  border-radius: 1.709vw;
  background: #23262f;
  margin: 0 3.819vw;
}

.home2 .marquee-parent .content {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee2 8s linear infinite;
}

@keyframes marquee2 {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-60%);
  }
}

.home2 .home-about-h2 h2.line {
  height: 3.25rem;
}

.home2 .home-about-h2 p {
  font-weight: 300;
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin: 0;
  margin-top: 24px;
  color: #e6e8ec;
}

.v2-section2 {
  position: relative;
  background-color: #141416;
  background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-home-bg-gradi-img.png");
  background-repeat: no-repeat;
  background-position: left bottom;
  overflow: hidden;
}
.v2-home-shape-img2 img {
  position: absolute;
  top: 0;
  left: 0;
  width: 55%;
  height: auto;
}
.v2-section2 .v2-section2-cont {
  padding: 19.444vw 0 !important;
}

.v2-home-about .home-about-inner1 {
  padding: 52px 0;
  border-bottom: 1px solid #d6d6d6;
}

.v2-section2 .home-about-h2 h2 span {
  color: #e6e8ec !important;
}
.v2-home-about .home-about-h2 h2 span {
  color: #23262f;
}

.home2 .home-about-h2 h2 span {
  color: #23262f;
  font-weight: 300;
  font-size: 2.438rem;
  line-height: 2.5rem;
}

.v2-section2 .extra-button {
  background: transparent;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  transition: 0.5s linear;
  padding: 14px 20px;
  color: #e6e8ec;
  border: 1px solid #e6e8ec;
  border-radius: 97px;
  text-decoration: none;
  display: inline-block;
}

.v2-section2 .extra-button:hover {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  padding: 14px 20px;
  color: #23262f;
  background-color: #e6e8ec;
  border: 1px solid #23262f;
  border-radius: 97px;
  text-decoration: none;
}

.wrapper {
  display: inline-flex;
}
.wrapper .dynamic-txts {
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  margin: -15px 0 0 0;
  padding: 0 0 0 0.6rem;
}
.dynamic-txts li {
  color: #e6e8ec;
  list-style: none;
  font-size: 2.438rem;
  /* line-height: 2.5rem; */
  position: relative;
  top: 0;
  animation: slide 8s steps(4) infinite;
}
@keyframes slide {
  100% {
    top: -280px;
  }
}
.dynamic-txts li .animtxt {
  position: relative;
}
.dynamic-txts li .animtxt::after {
  content: "";
  position: absolute;
  left: 0;
  height: 110%;
  width: 100%;
  background: #141416;
  border-left: 2px solid rgb(255, 255, 255);
  animation: typing 2s steps(10) infinite;
}
@keyframes typing {
  100% {
    left: 100%;
    margin: 0 -35px 0 35px;
  }
}

.home2 .home-about .extra-button-col {
  margin-top: 0px;
}

.home-about-h2 {
  padding-bottom: 26px;
}

.v2-home-about-new-inner2 {
  display: flex;
  padding: 76px 0;
}

.home-about-new-inner2 .cover {
  margin-right: 17.778vw;
}

.home-about-new-inner2 h3 {
  font-weight: 300;
  font-size: 2.438rem;
  line-height: 2.5rem;
  margin: 24px 0;
}

.home-about-new-inner2 p {
  color: #23262f;
  font-size: 1.25rem;
  line-height: 2.5rem;
  font-weight: 300;
}

.v2-home-work-lead .home-about-inner1 {
  padding: 76px 0;
}
.v2-home-work-lead .home-about-h2 h2 span {
  color: #23262f;
}

.v2-home-work-lead .home-about-h2 h2.line {
  height: 3.4vw;
}

.v2-home-work-lead .home-about-h2 p {
  color: #23262f;
}

.v2-home-work .home-work-parent h3 {
  color: #23262f;
  font-size: 1.5rem;
  line-height: 2rem;
}
.v2-home-work .home-work-parent p {
  color: #353945;
  font-size: 1rem;
  line-height: 1.375rem;
}

.home-cta {
  margin: 50px 0 0 0;
  padding: 64px 0;
  border-top: 0.5px #353945 solid;
  border-bottom: 0.5px #353945 solid;
}

.home-cta .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-cta .home-about-h2 {
  padding-bottom: 0;
}
.home-cta .home-about-h2 p {
  color: #353945;
}

.left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.home-what-inner2 {
  border-top: 0.5px solid white;
}

.home-what-inner2-row {
  margin: 0;
  overflow: hidden;
  height: 50vw;
}

.home2 .home-client {
  padding: 50px 0 138px 0;
}

.home-client .home-about-inner1 {
  padding-bottom: 64px;
  padding-top: 0;
  color: #000;
}

.home-client .home-about-inner1 p {
  color: #23262f;
}

.home2 .home-testimonials-inner * {
  color: #23262f;
}

.home2 .home-testimonials-inner h3 {
  font-size: 1.25rem;
  line-height: 2rem;
  margin-bottom: 3.375rem;
}
.home2 .home-testimonials-inner h4 {
  font-size: 1rem;
  line-height: 1.5rem;
}
.home2 .home-testimonials-inner h5 {
  font-size: 0.8rem;
  line-height: 1.063rem;
}

.home2 .slick-prev:before,
.home2 .slick-next:before {
  filter: invert(1);
}

.home2 .clientrows {
  margin-bottom: 24px;
}

.home2 .logos {
  width: 202px !important;
  height: 94px !important;
  aspect-ratio: 202/94 !important;
}

.home-faq {
  position: relative;
  background-color: #141416;
  background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-home-bg-gradi-img2.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  overflow: hidden;
  margin: 0px 0 0 0;
  padding: 90px 0;
  border-top: 0.5px #fff solid;
  border-bottom: 0.5px #fff solid;
}

.v2-home-faq .home-about-h2 h2 span {
  color: #e6e8ec;
}

.home-faq .home-about-inner1 {
  padding-bottom: 58px;
  padding-top: 0;
}

.home-faq .accordion {
  max-width: 55.556vw;
}

.home-faq .accordion-button::after {
  width: 1.389vw;
  height: 1.389vw;
  background-size: 1.389vw;
}

.home-faq .accordion-button {
  padding: 1.389vw 0;
  font-size: 1.736vw;
  font-weight: 300;
  background-color: transparent;
}

.home-faq .accordion-body {
  padding: 1.389vw 0 2.778vw 0;
  font-size: 1.111vw;
  line-height: 1.736vw;
}

.home2 .footer-bg {
  background-color: #141416;
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;
  /* background-image: url('https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-home-bg-gradi-img3.png'); */
  /* background-size: cover; */
  background-repeat: no-repeat;
  opacity: 1;
  margin-top: 0px;
}
.contact-hero-gra {
  pointer-events: none;
  opacity: 0.7;
  /* background-image: url('https://ik.imagekit.io/fingerprintsfuture/fps/tr:f-auto/v2-home-bg-gradi-img3.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  z-index: 0;
  position: absolute;
  width: 50%;
  height: 150%;
  right: 0;
  top: 0;
}

.home2 .plop {
  filter: invert(1);
}

.home2 .mc {
  margin-bottom: 16px;
  width: 90%;
}

.home2 footer h2 span {
  font-weight: 300;
  font-size: 2.713vw;
  line-height: 2.778vw;
}

footer h2 {
  font-weight: 300;
  font-size: 2.438rem;
  line-height: 3.25rem;
}

.contact-page {
  background: white;
}

footer .footer-ff {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px 0;
}
.footer-ff h2 {
  padding-top: 0;
}

.footer-bg {
  background-color: transparent;
}
.home2 .footer-bg {
  background-color: transparent;
}
footer h2 {
  color: #23262f;
}
.footer-content p {
  font-weight: 500;
  font-size: 0.972vw;
  line-height: 1.528vw;
  color: #23262f;
}
label {
  color: #23262f;
}
input,
textarea {
  color: #23262f;
  border: 0.5px solid #23262f;
}
.form-control {
  color: #23262f;
  border: 1px solid #23262f;
}
.form-control:hover,
.form-control:focus {
  background-color: rgb(255, 255, 255);
  color: #23262f;
}
.footer-content h5 {
  color: #23262f;
}
.footer-content .mail {
  color: #23262f;
}
.footer-content .number {
  color: #23262f;
}
.copyrights p {
  color: #23262f;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.container0 {
  position: relative;
  width: auto;
  /* margin: 100px auto; */
  user-select: none;
}

.slogan-container {
  position: relative;
  top: 0px;
  right: 0px;
}

.slogan {
  text-rendering: optimizeLegibility;
  margin: 0px;
  font-size: 2.438rem;
  color: #e6e8ec;
}

.slogan div {
  display: block;
  position: relative;
  opacity: 0;
  margin: -12px 0px 0 6px;
}

.services2 .home-hero .container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* min-height: 100vh; */
  min-height: 55.667vw; /* video height 55.667vw */
}
.process-hero .v2-home-banner-shape-img img {
  top: -10vw;
}

.services2 .process-hero h1.line {
  height: 4.55rem;
}
.services2 .process-hero .process-hero-content h1.line span {
  font-weight: 200;
  font-size: 3.5rem;
  line-height: 4.55rem;
}

.process-hero .marquee-parent {
  border: 1px solid #d6d6d6;
  border-left: 0px;
  border-right: 0px;
  position: relative;
  max-width: 100%;
  height: 9.014vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.process-hero .marquee-e {
  line-height: 4.563rem;
  font-size: 3.313rem;
  display: flex;
}

.process-hero .marquee-e span {
  display: inline-block;
  align-self: center;
  width: 1.709vw;
  height: 1.709vw;
  border-radius: 1.709vw;
  background: #23262f;
  margin: 0 3.819vw;
}

.process-hero .marquee-parent .content {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee2 12s linear infinite;
}

@keyframes marquee2 {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-60%);
  }
}

.services2 .our-approach-cont h2.line {
  display: flex !important;
  justify-content: center;
}
.services2 .process-approach {
  margin-bottom: 38px;
}
.services2 .chip {
  display: inline-block;
  padding: 8px 24px;
  border-radius: 100px;
  border: 1px solid #000000;
  margin: 0 16px 16px 0;
}
.services2 .chip span {
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #000;
  background: none;
  -webkit-background-clip: none;
  -webkit-text-fill-color: black;
  background-clip: none;
}
.services2 .chips-cover {
  margin-top: 42px;
}

.services2 .our-work-process-cont {
  border-top: 1px solid #d6d6d6;
  border-bottom: 1px solid #d6d6d6;
}

.services2 .our-work-process-cont h2 {
  color: #23262f;
  font-size: 2.438rem;
  font-weight: 400;
  line-height: 3.313rem;
  margin-bottom: 16px;
}
.our-work-process-cont h2 {
  background: none;
  -webkit-background-clip: #23262f;
  -webkit-text-fill-color: #23262f;
  background-clip: none;
}

.services2 .our-work-process-cont p {
  color: #23262f;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.813rem;
}
.services2 .owpis-1 .col-lg-6:nth-child(2) {
  padding-left: 38px;
}

.services2 .v2-home-work h2.line span {
  position: absolute;
  font-weight: 300;
  font-size: 2.441rem;
  line-height: 3.313rem;
  margin: 0;
}
.services2 .v2-home-work h2.line {
  position: relative;
  height: 3.313rem;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
}

.v2-blog .work-hero-cont h3 {
  color: #23262f;
  font-size: 3.333vw;
  font-weight: 400;
  line-height: 4.444vw;
}
.v2-blog .blog-crds-bdr {
  position: relative;
  height: 792px;
  border: 0.5px solid #000000;
  margin: 40px 0 0 0;
  padding: 14px;
}
.v2-blog .col-lg-12 .blog-crds-bdr {
  height: auto;
}
.v2-blog .blog-chip {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid #000000;
  margin: 0 12px 16px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
.v2-blog .blog-chip span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  background: none;
  -webkit-background-clip: #23262f;
  -webkit-text-fill-color: #23262f;
  background-clip: none;
}

.v2-blog .blog-featured-cont h3 {
  color: #23262f;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
}
.v2-blog .blog-featured-cont p {
  color: #23262f;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 0;
}
.v2-blog .blogs-post-cont h3 {
  color: #23262f;
  font-size: 28px;
  font-weight: 400;
  line-height: 40px;
}
.v2-blog .blogs-post-cont p {
  color: #23262f !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  margin-bottom: 0 !important;
}

.v2-blog .bth-left-p p {
  color: #23262f;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
}
.v2-blog .blog-content h2 {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.875rem;
  color: #23262f;
  margin-bottom: 0;
}
.v2-blog .blog-content h3 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 0;
}
.v2-blog .blog-du-cardcont h4 {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.5rem;
}
.v2-blog .blog-du-cardcont .txt-outline h4 {
  font-size: 2.875rem;
  font-weight: 300;
  line-height: 4.063rem;
  background: -webkit-linear-gradient(180deg, #ffffff 100%, #ffffff 0%);
  -webkit-background-clip: text;
  -webkit-text-stroke: 1px transparent;
  background-clip: text;
  color: rgba(255, 255, 255, 1);
}
.v2-blog .blog-content .blog-du-cardcont p {
  color: #fff;
  padding: 0 32px 0 0;
}
.v2-blog .blog-content p {
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.875rem;
  color: #23262f;
  margin-bottom: 0;
}
.v2-blog .blog-du-imgcont {
  position: relative;
  padding: 40px 0;
}
.v2-blog .txt-gradi h3 {
  background: linear-gradient(
    183deg,
    #f918ff 40.79%,
    #0267ff 110.21%,
    #0085ff 110.21%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: capitalize;
  font-weight: 600;
}
.v2-blog .txt-outline h4 {
  font-size: 3.264vw;
  font-weight: 300;
  line-height: 4.514vw;
  background: linear-gradient(
    42deg,
    #f918ff -11.21%,
    #0267ff 40.21%,
    #0085ff 30.21%
  );
  -webkit-background-clip: text;
  -webkit-text-stroke: 2px transparent;
  background-clip: text;
  color: #fff;
}
.v2-blog .txt-bdr-du h2 {
  border-top: 1px solid #3a3a3a;
  border-bottom: 1px solid #3a3a3a;
  margin: 24px 0;
  padding: 40px 10px 40px 0;
}
.v2-blog .blog-line-bdr {
  border-bottom: 1px solid #3a3a3a;
  margin: 30px 0;
}
.v2-blog .blog-sf img {
  width: 75%;
  filter: invert(1);
}

.v2-blog .daterow {
  position: absolute;
  bottom: 0px;
  padding-top: 6px;
  border-top: 0.5px solid #000000;
  display: flex;
  justify-content: space-between;
  width: 96%;
}

.v2-blog .blog-du-card .blog-du-card-img {
  height: 300px;
}
.v2-blog .blog-du-card .blog-rapd-card1 h4,
.v2-blog .blog-du-card .blog-rapd-card2 h4,
.v2-blog .blog-du-card .blog-rapd-card3 h4,
.v2-blog .blog-du-card .blog-rapd-card1 p,
.v2-blog .blog-du-card .blog-rapd-card2 p,
.v2-blog .blog-du-card .blog-rapd-card3 p {
  color: #fff;
}

.v2-blog .blog-du-coux-inner a {
  color: #23262f;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #23262f;
  border-radius: 52px;
  margin-bottom: 32px;
}
.v2-blog .blog-du-coux-inner {
  margin: 0 0 80px 0;
}
.v2-blog .blog-du-coux h3 {
  margin-bottom: 26px;
  padding: 0 0 26px 0;
  border-bottom: 1px solid #3a3a3a;
}
.v2-blog .blog-du-coux h3 span {
  background: linear-gradient(
    55deg,
    #f918ff -11.21%,
    #0267ff 110.21%,
    #0085ff 110.21%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
}

/* popular posts by NT */
.all-posts {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;
}

.post-container {
  display: flex;
  align-items: flex-start;
  gap: 23px;
  text-decoration: none;
}

.post-container p {
  color: #23262f;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  width: 228.757px;
}

.post-container img {
  width: 94.04px;
}

.post-container span {
  color: #b1b5c3;
  font-size: 12px;
  margin-top: 4px;
}

.back-btn {
  display: inline-block;
  padding: 0px;
  font-size: 14px;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 24px;
}

.back-btn:hover {
  color: #000;
}

.back-btn svg {
  margin-top: -3px;
  margin-right: 8px;
}

.socials-container {
  display: flex;
  gap: 4.89px;
}

/* .text-truncate {
    --max-lines: 3;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: var(--max-lines);
} */

/*----------------------------------------------------------- Home2 End -----------------------------------------------------------*/

/* -------------------- v2 Blog -------------------- */

.v2-blog .blog-temp-hero {
  padding-bottom: 20px;
}
.v2-blog .col-lg-8 {
  padding-right: 50px;
}
.nwv-blog1-hero h1 {
  font-size: 2.5rem;
  font-weight: 400;
  line-height: 3rem;
  margin-bottom: 16px;
}
.nwv-blog1-hero h2 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 24px;
}
.nwv-blog1-hero h3 {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.875rem;
  margin-bottom: 24px;
}
.nwv-blog1-hero p {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.75rem;
}

.nwv-blog1-hero-adt {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-bottom: 80px;
}
.nwv-blog1-hero-adt p {
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 2rem !important;
  margin-bottom: 0;
  color: #777e90;
}
.nwv-blog1-hero-adt img {
  width: auto;
  height: auto;
}

.v2-blog .blog-du-imgcont img {
  width: 100%;
}
.v2-blog .quote-bg {
  position: relative;
  background: url("./../assets/blog-du-bgi-2.png") no-repeat;
  background-size: cover;
  margin: 50px 0;
  padding: 42px 0;
}
.v2-blog .quote-bg .blog-du-img3 {
  position: absolute;
  top: -18px;
  left: 27px;
  width: 6%;
  filter: invert(1);
}
.v2-blog .quote-bg h2 {
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2rem;
  color: #000000;
  margin-bottom: 12px;
}
.v2-blog .quote-bg p {
  font-weight: 600;
  margin-bottom: 0;
}

.v2-blog .v2-blog-sidebar {
  position: relative;
  padding: 200px 0;
}
.v2-blog .v2-blog-sidebar-cont {
  border-left: 1px solid #3a3a3a;
  padding-bottom: 200px;
}

.v2-blog-sidebar-tags,
.v2-blog-sidebar-posts,
.v2-blog-sidebar-social {
  margin-left: 70px;
}
.v2-blog-sidebar-tags h4 {
  color: #23262f;
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
}
.v2-blog-sidebar-tags span {
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  text-decoration: underline;
  display: inline-block;
  margin-right: 12px;
}
.v2-blog-sidebar-posts h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  margin-top: 36px;
}
.v2-blog-sidebar-social h4 {
  font-size: 14px;
  font-weight: 700;
  line-height: 32px;
  text-transform: uppercase;
  margin-top: 36px;
}

/* NT blog styles */
.sub-txt-content {
  color: #23262f;
  font-size: 16px;
  font-weight: 300 !important;
  line-height: 26px;
}
.sub-txt-content span {
  margin-top: 20px;
}
.blog-content h4 {
  font-size: 20px;
  font-weight: 300;
  line-height: 30px;
}
.sidebar-card {
  background-image: url("https://ik.imagekit.io/fingerprintsfuture/fps/sidebar-card-bg.png?updatedAt=1699005160661");
  background-repeat: no-repeat;
  background-size: contain;
  margin: 56px 0 0 70px;
  padding: 46px 20px;
  color: #fff;
}

.sidebar-card h1 {
  font-family: Manrope;
  font-size: 40px;
  font-weight: 500;
  line-height: 48px;
  margin-bottom: 20px;
}

.sidebar-card .extra-button {
  background: transparent;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  transition: 0.5s linear;
  padding: 14px 20px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 97px;
  text-decoration: none;
  display: inline-block;
}

.sidebar-card .extra-button:hover {
  background-color: transparent !important;
  color: #fff;
}

/* -------------------- v2 Blog End -------------------- */

.tq .extra-logo {
  filter: invert(1);
}
.tq.extra-hero .extra-link {
  color: #23262f;
}
.tq.extra-hero .extra-row {
  align-items: center;
}

.nav4 .extra-button {
  background: transparent;
  transition: 0.5s linear;
  padding: 14px 0;
  border: none;
  border-radius: 97px;
  text-decoration: none;
  display: inline-block;

  font-weight: 400;
  font-size: 4.444vw;
  line-height: 6.042vw;
  display: inline-block;
  position: relative;
  color: #ffffff;
  transition: all 250ms linear;
  text-decoration: none;
}

.nav4 .extra-button:hover {
  background-color: transparent !important;
}

/* Mahasagher project */
.mahasaghar-video {
  padding-bottom: 60px;
}

.mahasaghar-img-padding {
  padding-bottom: 60px;
}

.mahasaghar-project-text {
  font-size: 24px;
}

/* Axe project */
.axe-cont {
  position: relative;
  padding: 65px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.axe-cont p {
  width: 430px;
}

.averlon-video1 {
  position: relative;
  padding: 80px 0;
}

.averlon-video1 video {
  border: 2px solid #000;
  border-radius: 10px;
}

.averlon-img2-3 img {
  background-color: #f3f3f3;
  border-radius: 16px;
}

/* .averlon-slider .swiper-wrapper {
  justify-content: center;
} */

/* .averlon-slider img {
  height: 780px;
} */

.para-width p {
  width: 400px;
}
